.aboutsection1-bg{
  background:$Grandis;
  height:383px;
}
.aboutcontent{
.carousel-indicators {
    left: 0;
    top: -50px;
    height: 50%;
}
.carousel-indicators li {
    background: #708198;
    border-radius: 50%;
    width: 8px;
    height: 8px;
    border: none
}
.carousel-indicators .active {
    background: #0fc9af;
}
}
.aboutsection3-bg {
    background:$Buttermilk;
    height: 630px;
}
.aboutsection7-bg{
  background:$TwilightBlue;
}
.aboutsection5-bg{
  background:$PeachSchnapps;
  .box {
    padding: 2rem;
	border-radius: 8px;
	box-shadow: none;
	min-height: 220px;
}
}
.aboutsection8-bg {
    padding: 3rem 2rem;

 .btn-send {
    background: $Amethyst;
    padding: .6rem 6rem !important;
    border-radius: 2rem;
    color: $White ;
}
}
.w-79 {
    width: 79% !important;
}
.socialLink{
    color: black;
}
.box {
    background: $white;
    padding: rem(10px);
    border-radius: 10px;
    box-shadow: 0px 10px 29px 0px rgba(68,88,144,0.1);
    height: auto;
    //height: 100%;
    .box--head{
    color:$TurquoiseBlue;
    font-size: 1.2rem;
    }
    .box--par{
    color:$Fedora;
    font-size: 1.2rem;

    }
}
.aboutsection7-bg .friends {
    display: inline-block;
}
.w-56 {
    width: 56% !important;
}
@media #{$information-large} {
.aboutsection1-bg {
    height: auto;
}
}
@media #{$information-tablet} {
.aboutsection1-bg {
    height: auto;
}
#aboutsection5 .w-75 {
    width: 90% !important;
}
.aboutsection3-bg {
    height: auto;
}
}
@media #{$information-phone} {
.aboutsection5-bg .w-80 {
    width: 90%;
}
.aboutsection4-bg .w-55{
     width: 90%;
}
.aboutsection4-bg h4,.aboutsection4-bg p{
    text-align:center;
}
.aboutsection6-bg h4,.aboutsection6-bg p{
    text-align:center;
    width: 100% !important;
}
.aboutsection2-bg h4,.aboutsection2-bg p{
    text-align:center;
    width: 100% !important;
}
.aboutsection1-bg {
    height: auto;
}
.aboutcontent section{
  padding-top:30px !important;
  padding-bottom:30px !important;
}
#aboutsection5 .w-75 {
    width: 90% !important;
}
}
.black-txt {
    font-size: 1.2rem;
}
.aboutus-content {
    background: #fff;
    margin-left: -62px;
    font-family: "Msemibold" !important;
}
 
.font-48 {
    font-size: 3rem;
}
 
.team-content {
    justify-content: space-between;
    margin-top: 24px !important;
}
 
.team-image img {
    object-fit: contain;
    object-position: top;
    width: 350px;
    height: 350px;
}
 
.team-image, .team-content {
    margin: 0 25px 0 0px;
}
 
.info i.fa {
    background-color: #0976b4;
    display: block;
    line-height: 1.2rem;
    text-align: center;
    color: #fff!important;
    font-size: 1.2rem;
    padding-top: 0.3125rem;
    height: 25px;
    width: 24.79px;
    border-radius: 3px;
}
.abt-modal figure img {
    width: 320px;
    height: 320px;
}
 
.font-38 {
    font-size: 2.375rem;
}
 
.font-28 {
    font-size: 1.75rem;
}
 
.abt-modal .modal-body {padding-left: 50px;padding-bottom: 50px;}
 
.abt-modal .info i.fa {
    background-color: #0976b4;
    display: block;
    text-align: center;
    color: #fff!important;
    font-size: 3rem;
    padding-top: 0.3125rem;
    height: 50px;
    width: 50px;
    border-radius: 3px;
    margin-right: .222rem;
}
 
.abt-modal button.close span img {
    width: 25px;
    height: 25px;
}
 
.abt-modal .modal-header {
    padding-right: 50px;
    padding-top: 30px;
    padding-bottom: 0;
}
 
.abt-modal .modal-content {
    border-radius: 50px;
}
.section-header figure {
    Width: 200px;
    margin: 0 auto;
}
 
.aboutus-page #aboutsection2 .row {
    position: relative;
}
 
.aboutus-page #aboutsection2 .aboutus-content {
    position: absolute;
    right: -50px;
}
@media only screen and (min-width: 1435px) and (max-width: 1443px) {
    .section-header figure {
        width: 560px;
    }
    .aboutus-content p {
        font-size: 1.05rem!important;
    }
}
@media only screen and (min-width: 1360px) and (max-width: 1370px) {
    .section-header figure {
    Width: 560px;
}
    .aboutus-content p {
        padding-right: 0 !important;
    }
    .aboutus-content {
        padding-bottom: 25px !important;
        padding-top: 30px !important;
        right: -80px !important;
    }
    .team-image img {
        width: 300px;
        height: 300px;
    }
    .team-content, .team-image {
    margin: 0 35px 0 0;
}
}
@media only screen and (min-width: 1275px) and (max-width: 1285px) {
    .section-header figure {
    Width: 500px;
}
    .aboutus-content p {
        padding-right: 0 !important;
    }
    .aboutus-content {
        padding-bottom: 25px !important;
        padding-top: 30px !important;
        right: -60px !important;
    }
    .team-image img {
        width: 300px;
        height: 300px;
    }
    .team-content, .team-image {
    margin: 0 10px 0 0;
}
}
@media only screen and (max-width: 767px) {
    .abt-modal {
        display: none;
    }
    #aboutsection2.display-section, #aboutsection3.display-section{
        display: none;
    }
    .modal-top-bar, .row.row-box.modal-reponsive {
        display: block;
    }
    .aboutus-page #aboutsection2 .aboutus-content {
        position: revert !important;
        margin-top: 0 !important;
        padding-top: 30px !important;
        padding-left: 30px !important;
        padding-right: 30px !important;
        margin-bottom: 0 !important;
        padding-bottom: 0 !important;
    }
    .aboutus-page .container-fluid {
        width: 100%;
    }
    .aboutus-page #aboutsection2 .aboutus-content h4 {
        margin-bottom: 15px !important;
        font-size: 1.75rem !important;
    }
    .aboutus-page #aboutsection2 .aboutus-content p {
        font-size: 1rem!important;
        line-height: 1.563rem !important;
        padding-right: 0 !important;
    }
    .aboutcontent.aboutus-page section {
        padding-bottom: 0px!important;
    }
    .aboutus-page .section-header {
        padding-bottom: 0 !important;
    }
    .our-leadership h4 {
        font-size: 1.5rem !important;
        text-align: center;
        margin-bottom: 30px !important;
    }
    .our-leadership .container-fluid {
        width: 100% !important;
    }
    .team-image img {
        width: 100%;
        height: 100%;
    }
    .team-image {
        text-align: center;
        margin: 0;
    }
    .team-image, .team-content {
        margin-left: 15px;
        margin-right: 15px;
    }
    .team-content {
        margin-top: 15px !important;
    }
    .mobile-m0 {
        margin-bottom: 30px !important;
    }
}
@media only screen and (max-width: 767px) {
    .aboutus-page #aboutsection2 .aboutus-content {
        margin-left: 0 !important;
    }
}
.abt-modal .aboutus-content {
    margin-left: initial;
}
 
.abt-modal .info i.fa {
    line-height: normal;
}
.abt-modal .aboutus-content {
    margin-left: initial;
}
.abt-modal .info i.fa {
    line-height: normal;
} 
.row-box {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  .row-box .col-6 {
    width: 48%;
    position: relative;
  }

  .row-box .content-box {
    width: 48%;
    background: white;
    position: absolute;
    right: -30px;
    height: 90%;
    top: 30px;
  }
@media only screen and (min-width: 1360px) and (max-width: 1445px) {
  .row-box .content-box p {
      font-size: 17px !important;
  }
  .row-box .content-box {
      right: -80px;
  }
  .about-us-detail-popup.abt-modal, .about-us-detail-popup.abt-modal .modal-dialog {
    width: 900px !important;
 }
}
@media only screen and (min-width: 1275px) and (max-width: 1285px) {
  .row-box .content-box {
      right: -115px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1990px) {
    .abt-modal {
        display: block;
    }
    #aboutsection2.display-section, #aboutsection3.display-section {
        display: block;
    }
    .modal-top-bar, .row.row-box.modal-reponsive {
        display: none;
    }
}
@media only screen and (min-width: 768px) and (max-width: 1199px) {
  .row-box {
  display: block;
  }
  .row-box .col-6 img {
      width: 100%;
  }
  .row-box .content-box {
      width: 100%;
      height: 100%;
      clear: both;
      position: revert;
      margin-bottom: 0 !important;
      padding-left: 15px !important;
  }
  .row-box .col-6 {
      width: 100%;
      clear: both;
      max-width: 100%;
  }
  .aboutus-page .aboutsection2-bg {
      padding-bottom: 0px !important;
  }
  .team-image img {
    width: 100%;
    height: 150px;
  }
  .team-content {
      margin-top: 10px !important;
  }
  .team-info h3, .team-info p {
      font-size: 1rem !important;
  }
}
@media(max-width: 767px) {
  .row-box {
  display: block;
  }
  .row-box .col-6 img {
      width: 200px;
  }
  .row-box .content-box {
      width: 100%;
      height: 100%;
      clear: both;
      position: revert;
      margin-bottom: 0 !important;
      padding-left: 15px !important;
  }
  .row-box .col-6 {
      width: 100%;
      clear: both;
      max-width: 100%;
  }
  .aboutus-page .aboutsection2-bg {
      padding-bottom: 0px !important;
  }
  .aboutus-page #aboutsection2 .content-box h4 {
      margin-bottom: 15px !important;
      font-size: 1.75rem !important;
  }
  .aboutus-page #aboutsection2 .content-box p {
      font-size: 1rem!important;
      line-height: 1.563rem !important;
      padding-right: 0 !important;
  }
  .aboutus-page #aboutsection2 .content-box {
      position: revert !important;
      margin-top: 0 !important;
      padding-top: 30px !important;
      padding-left: 30px !important;
      padding-right: 30px !important;
      margin-bottom: 0 !important;
      padding-bottom: 0 !important;
  }
}
.abt-modal .aboutus-content {
    margin-left: -3px;
    position: revert;
}
.abt-modal .info i.fa {
    margin-right: 0;
    margin-top: 1rem;
}
.info a:hover {
    text-decoration: none;
}
.modal-top-bar {
  background-color: #87ACB6;
  text-align: left;
  padding: 6px 15px;
  position: fixed;
  width: 100%;
  top: 80px;
  z-index: 1000;
  height: 30px;
}
 
.back-arrow {
  background: none;
  border: none;
  display: flex;
  color: #FFFFFF;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.01px;
  line-height: 17px;
  align-items: end;
}
.element {
  width: 0px;
  height: 0px;
  margin-right: 5px;
}
.element-7 {
  border-top: 8px solid transparent;
  border-right: 15px solid #fff;
  border-bottom: 8px solid transparent;
  border-left: 1px solid transparent;
}
/** About new css
**/

.team-image {
    width: 362px;
    height: 362px;
    overflow: hidden;
  }
   
  .team-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
  }
   
  .team-content {
    justify-content: space-between;
    align-items: start;
    margin-top: 24px !important;
    width: 362px;
  }
   
  .team-info {
    flex-grow: 1;
    padding-right: 60px; 
  }
   
  .info {
    margin-left: auto;
  }
   
  .info i.fa {
    background-color: #0976b4;
    border-radius: 3px;
    color: #fff !important;
    display: block;
    font-size: 1.2rem;
    height: 25px;
    line-height: 1.2rem;
    padding-top: 0.3125rem;
    text-align: center;
    width: 24.79px;
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .team-image {
      width: 300px;
      height: 300px;
  }
    .team-content {
      width: 300px;
  }
  }
  @media only screen and (min-width: 768px) and (max-width: 1199px) {
    .team-image {
      width: auto; 
      height: auto;
      max-width: 100%; 
    }
   
    .team-content {
      width: auto; 
    }
      .aboutus-page .m-b-60.mobile-m0 {
          margin-bottom: 30px !important;
      }
    .team-info {
      padding-right: 20px;
  }
    .team-image img {
      height: 200px !important; /* add important*/
      width: 100%;
      object-position: center;
  }
  }
  @media only screen and (max-width: 767px) {
  .col-12.mobile-m0 { /* add classname .col-12*/
      margin-bottom: 30px!important;
  }
  .team-content, .team-image {
      margin-left: 15px; 
      margin-right: 15px;
      margin: auto; /* new*/
      width: 330px;
  }
    body .team-content { /* add classname body*/
      margin-top: 15px!important;
  }
  }
/** css ends
**/
 
.aboutus-page #aboutsection2 .modal-reponsive .content-box .ash-txt, .aboutus-page #aboutsection2 .modal-reponsive .content-box .modal-position {
    font-size: 1.125rem !important;
    line-height: 1.25rem;
    margin-bottom: 2px !important;
}
.aboutus-page #aboutsection2 .modal-reponsive .content-box .modal-position {
    margin-bottom: 30px !important;
    color: #53565A;
}
.row.row-box.modal-reponsive {
    margin-top: 35px;
}
.row.row-box.modal-reponsive p.ash-txt {
    padding-bottom: 50px;
    margin-bottom: 0;
}
.row.row-box.modal-reponsive {
    text-align: center;
}
.modal-reponsive .content-box {
    padding-top: 43px !important;
    padding-bottom: 0 !important;
    padding-right: 0 !important;
}
.modal-reponsive .content-box h4.ash-txt {
    margin-bottom: 0px !important;
    font-size: 1.125rem !important;
    line-height: 1.25rem;
}
.modal-reponsive .content-box  p.modal-position {
    margin-bottom: 1.875rem;
    text-align: center;
    font-size: 1.125rem;
    line-height: 1.25rem;
}
.modal-reponsive .content-box p.ash-txt {
    font-size: 1rem !important;
    line-height: 1.25rem;
    margin-bottom: 0 !important;
    text-align: center;
    padding-right: 0 !important;
}
.abtimage-after, .abtcontent-after {
    position: relative;
}
 
.abtimage-after:before {
    background: url(/assets/images/Decor.png);
    height: 260px;
    bottom: -65px;
    left: -155px;
    z-index: 9;   
}
.abtimage-after:before, .abtcontent-after:after {
    content: '';
    position: absolute;
    background-repeat: no-repeat;
    width: 100%;
}
.abtcontent-after:after {
    background: url(/assets/images/BlueCircle.png);
    top: -80px;
    right: -55px;
    z-index: -1;
    width: 196px;
    height: 196px;
}
 
.aboutcontent.aboutus-page #aboutsection2 {
    padding-top: 75px !important;
}
.abtimage-after, .abtcontent-after {
    position: relative;
}
.abtimage-after:before {
    background: url(/assets/images/Decor.png);
    height: 260px;
    bottom: -65px;
    left: -155px;
    z-index: 9;   
}
.abtimage-after:before, .abtcontent-after:after {
    content: '';
    position: absolute;
    background-repeat: no-repeat;
    width: 100%;
}
.abtcontent-after:after {
    background: url(/assets/images/BlueCircle.png);
    top: -80px;
    right: -55px;
    z-index: -1;
    width: 196px;
    height: 196px;
}
.aboutcontent.aboutus-page #aboutsection2 {
    padding-top: 75px !important;
}
 
@media only screen and (min-width: 768px) and (max-width: 1199px) {
.abtimage-after:before {
    height: 170px;
    bottom: -15px;
    left: -80px;
    background-size: contain;
}
.abtcontent-after:after {
    top: -65px;
    right: -40px;
    width: 150px;
    height: 150px;
    background-size: contain;
}
}
.abtimage-after, .abtcontent-after {
    position: relative;
}
.abtimage-after:before {
    background: url(/assets/images/Decor.png);
    height: 260px;
    bottom: -65px;
    left: -155px;
    z-index: 9;   
}
.abtimage-after:before, .abtcontent-after:after {
    content: '';
    position: absolute;
    background-repeat: no-repeat;
    width: 100%;
}
.abtcontent-after:after {
    background: url(/assets/images/BlueCircle.png);
    top: -80px;
    right: -55px;
    z-index: -1;
    width: 196px;
    height: 196px;
}
.aboutcontent.aboutus-page #aboutsection2 {
    padding-top: 75px !important;
}
 
@media only screen and (min-width: 768px) and (max-width: 1199px) {
.abtimage-after:before {
    height: 170px;
    bottom: -15px;
    left: -80px;
    background-size: contain;
}
.abtcontent-after:after {
    top: -65px;
    right: -40px;
    width: 150px;
    height: 150px;
    background-size: contain;
}
}
@media only screen and (max-width: 767px) {
    .abtimage-after:before {
        background: url(https://dev.beamfeelgood.com/images/Decor.png);
        height: 90px;
        bottom: -25px;
        left: 80px;
        z-index: 9;
        background-size: contain;
        background-repeat: no-repeat;
        width: 95px;
    }
    .abtcontent-after:after {
    background: url(https://dev.beamfeelgood.com/images/BlueCircle.png);
    top: -40px;
    right: 90px;
    z-index: -1;
    width: 96px;
    height: 96px;
    background-size: contain;
    background-repeat: no-repeat;
}
    .aboutcontent.aboutus-page #aboutsection2 {
    padding-top: 40px !important;
}
    .aboutus-page #aboutsection2 .content-box { /*Find and replace padding value*/
        padding: 50px 30px 0!important;
    }
}
@media (min-width: 340px) and (max-width: 380px) {
    .abtimage-after:before {
        background: url(https://dev.beamfeelgood.com/images/Decor.png);
        height: 90px;
        bottom: -25px;
        left: 55px;
        z-index: 9;
        background-size: contain;
        background-repeat: no-repeat;
        width: 95px;
    }
    .abtcontent-after:after {
    background: url(https://dev.beamfeelgood.com/images/BlueCircle.png);
    top: -40px;
    right: 60px;
    z-index: -1;
    width: 96px;
    height: 96px;
    background-size: contain;
    background-repeat: no-repeat;
}
    .aboutcontent.aboutus-page #aboutsection2 {
    padding-top: 40px !important;
}
    .aboutus-page #aboutsection2 .content-box { /*Find and replace padding value*/
        padding: 50px 30px 0!important;
    }
}
@media (min-width: 100px) and (max-width: 320px) {
    .abtimage-after:before {
        background: url(https://dev.beamfeelgood.com/images/Decor.png);
        height: 90px;
        bottom: -25px;
        left: 30px;
        z-index: 9;
        background-size: contain;
        background-repeat: no-repeat;
        width: 95px;
    }
    .abtcontent-after:after {
    background: url(https://dev.beamfeelgood.com/images/BlueCircle.png);
    top: -40px;
    right: 40px;
    z-index: -1;
    width: 96px;
    height: 96px;
    background-size: contain;
    background-repeat: no-repeat;
}
    .aboutcontent.aboutus-page #aboutsection2 {
    padding-top: 40px !important;
}
    .aboutus-page #aboutsection2 .content-box { /*Find and replace padding value*/
        padding: 50px 30px 0!important;
    }
}
@media only screen and (max-width: 767px) {
    .row.row-box.modal-reponsive {
       margin-top: 57px;
    }
    .row-box .responsive-modal-popup {
       position: relative;
    }
    .row-box .responsive-modal-popup:after {
       background: url(
    https://dev.beamfeelgood.com/images/Decor.png);
       height: 90px;
       bottom: -35px;
       left: 80px;
       z-index: 9;
       background-size: contain;
       background-repeat: no-repeat;
       width: 95px;
       content: '';
       position: absolute;
    }
    .row-box .responsive-modal-popup:before {
       background: url(
    https://dev.beamfeelgood.com/images/BlueCircle.png);
       top: -30px;
       right: 95px;
       z-index: -1;
       width: 66px;
       height: 96px;
       background-size: contain;
       background-repeat: no-repeat;
       content: '';
       position: absolute;
    }
    }
    @media (min-width: 340px) and (max-width: 380px) {
    .row-box .responsive-modal-popup:after {
       left: 55px;
    }
    .row-box .responsive-modal-popup:before {
       right: 70px;
    }
    }
    @media (min-width: 100px) and (max-width: 320px) {
       .row.row-box.modal-reponsive {
       margin-top: 57px;
    }
    .row-box .responsive-modal-popup:after {
       bottom: -40px;
       left: 20px;
    }
    .row-box .responsive-modal-popup:before {
       top: -30px;
       right: 45px;
    }
}
.abt-modal .modal-desktop-image {
    position: relative;
    height: 362px;
}
.about-us-detail-popup.abt-modal {
    width: 100%;
}
@media (min-width: 992px) {
    .about-us-detail-popup.abt-modal .modal-dialog.modal-dialog-centered {
       max-width: 896px;
    }
}
.abt-modal .modal-desktop-image::after {
    content: "";
    position: absolute;
    background: url(/assets/images/Decor2.png)  no-repeat;
    z-index: 99;
    background-size: contain;
    bottom: -50px;
    height: 142px;
    left: -22px;
    width: 190px;
}
 
.abt-modal .modal-desktop-image img {
    position: relative;
    z-index: 10;
    height: 100%;
    object-fit: cover;
    object-position: top;
    width: 100%;
}
 
@media (min-width: 768px) and (max-width: 991px) {
    .about-us-detail-popup.abt-modal, .about-us-detail-popup.abt-modal .modal-dialog {
        max-width: 100%!important;
    }
}
