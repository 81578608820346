.video-container .video-react {
    border-radius: 15px; /* Adjust the border radius as needed */
    overflow: hidden; /* Ensure the border radius is applied correctly */
  }
  
  .progress-percent .progress, .session-progress .progress {
    overflow: inherit;
}

.interactive-activity {
  figure {
    position: relative;
    display: inline-block;
    width: 100%; // Adjust as needed
    height: 100%; // Adjust as needed

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      border-radius: 16px;
    }

    .play-button {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 60px; // Adjust size as needed
      height: 60px; // Adjust size as needed
      // background: url('/assets/images/play-icon.png') no-repeat center center;
        background-size: contain;
      cursor: pointer;
    }
  }
}