.confidence-labels {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 5px; // Adjust margin as needed

    .label {
      font-size: 12px; // Adjust font size as needed
      color: #999;
    }
  }
  .golas-datas .data-scroll {
    height: 300px;
    overflow: hidden;
  }
  .golas-datas .data-scroll:hover {
    overflow-y: scroll;
  }
  .golas-datas .data-scroll:hover::-webkit-scrollbar {
  width: 5px;
  margin-left:5px;
  }
  .golas-datas .data-scroll:hover::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
  }
  .golas-datas .data-scroll:hover::-webkit-scrollbar-thumb {
  background: #456EB0; 
  border-radius: 10px;
  }
  .golas-datas .data-scroll:hover::-webkit-scrollbar-thumb:hover {
  background: #456EB0; 
  }
  .createagoalhover:hover {
    background-color: #e0e4ec !important;
    border: none !important;
  }
  .custom-date-input::-webkit-inner-spin-button,
  .custom-date-input::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
  }

  .custom-date-input {
    position: relative;
  }

  .custom-date-input::placeholder {
    color: #999; /* Customize placeholder color */
  }

  .create-goal-form .form-control:focus {
    box-shadow: 0 0 0 0.1rem #495c87;
}
.data-scroll .goals-list-item:last-child {
  margin-bottom: 0 !important;
}
.date-input-wrapper {
  position: relative;
  display: inline-block;

  .date-input {
    padding-right: 30px; // Adjust padding to prevent overlap with the icon
  }

  .calendar-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    font-size: 18px; // Adjust the size of the icon as needed
  }
}
