.blog_page {
    .banner_blog {
        background: url(/assets/images/blog_bg.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
        height: auto !important;
    }
    .section1-bg {
        background: url(/assets/images/bg-banner.png);
        //height: 383px;
        background-size: 100% 100%;
    }
    .fixed_blog_height {
        height: 520px !important;
    }
    .tab_group {
        margin-top: -31px;
        .tab_full {
            z-index: 1;
            .tab_ul {
                border: 1px solid $Mercury;
                background: #fff;
                border-radius: 5px;
                padding: 4px;
                .nav-item {
                    border-right: 1px solid $Mercury;
                    //padding: 0px 39px;
                    //padding: 0px 20px;
                    padding: 0px 10px;
                    margin: 0;
                    .nav-link {
                        color: $Aluminium;
                        border: none;
                        font-size: 0.7rem;
                    }

                    .nav-link.active {
                        border-bottom: 4px solid $TurquoiseBlue;
                        border-top: 0;
                        border-right: 0;
                        border-left: 0;
                        position: relative;
                        top: 4px;
                        color: $Black;
                    }
                }
                .nav-item:last-child {
                    border-right: 0;
                }
            }
        }
    }
}
.social_blog {
    position: absolute;
    right: -35px;
    top: 0;
    width: 35px;
}
.social_blog .fa {
    float: left;
    clear: both;
    margin-bottom: 12px;
    font-size: 17px;
    padding-left: 8px;
}
.share-section span {
    margin: 8px;
}
.social_blog .fa.fa-bookmark-o {
    color: $Fedora;
}
.social_blog .fa.fa-twitter {
    color: $Cerulean;
}
.blog_details_container p {
    color: #2a2b2c;
}
.author_bold {
    font-weight: bold;
}
.blog_details_container .blog_dyn_section p,
.blog_details_container .blog_dyn_section span,
.blog_details_container .blog_dyn_section div {
    font-family: "Qregular";
    color: #2a2b2c;
}
.blog_details_container .blog_dyn_section h1 {
    font-family: "Mregular" !important;
    color: $OutrageousOrange;
    font-size: rem(28px);
}
.blog_details_container .blog_dyn_section h2 {
    font-family: "Msemibold";
    color: $TurquoiseBlue;
    font-size: rem(24px);
}
.blog_details_container .blog_dyn_section h3 {
    font-family: "Msemibold";
    color: $Amethyst;
    font-size: rem(22px);
}
.blog_details_container .blog_dyn_section h4 {
    font-family: "Msemibold";
    color: $OutrageousOrange;
    font-size: rem(20px);
}
.blog_details_container .blog_dyn_section h5 {
    font-family: "Msemibold";
    color: $Lochmara;
    font-size: rem(18px);
}
.blog_details_container .blog_dyn_section h6 {
    font-family: "Msemibold";
    color: $GoldenTainoi;
    font-size: rem(18px);
}
.blog_details_container p {
    color: #2a2b2c;
    font-family: "Qregular" !important;
    font-size: rem(19.2px);
}
.blog_details_container ul,
.blog_details_container ol {
    margin-left: 3em;
    font-family: "Qregular";
    font-size: 1.2em;
}
.social_blog .fa.fa-facebook {
    color: $Chambray;
}
.image-text-blog img {
    border-radius: 4px;
    height:100%;
}
.image-text-blog{
   height:95%;
}
.video_height iframe{
    height:95% !important;
    width:95% !important;
}
.mobile-view-blog {
    display: none;
}
@media #{$information-xtralarge} {
    .banner_blog {
        background: url(/assets/images/blog_bg.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;

        height: 383px;
    }
    .blog_details_page .fixed_blog_height {
        height: 550px !important;
    }
}

a {
    color: $OutrageousOrange;
}
.filter-block {
    margin-bottom: 1.5em;
}
.smaller.primary {
    color: #9966cc;
    text-align: left;
    background-color: transparent;
}
.smaller.marge-bottom {
    margin-bottom: 1.5em;
}
.primary {
    text-align: center;
    background-color: #5c1060;
    position: relative;
}
.marge-bottom {
    margin-bottom: 2vw;
}
.smaller {
    color: #9966cc;
    text-align: left;
    flex: 0 auto;
    align-self: center;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.2em;
}
.input-icon {
    color: #000;
    align-items: center;
    display: block;
    position: relative;
}
.input-icon .input {
    height: 3.2em;
    color: #2f0b33;
    border: 2px solid #cdbeba;
    border-radius: 500px;
    margin-bottom: 0;
    padding: 0.5em 1.2em;
    font-size: 1em;
    font-weight: 600;
    line-height: 1.4em;
}
.input-icon .input::placeholder {
  color: #999999;
  opacity: 1; 
}

 

.input-icon .input:-ms-input-placeholder {
  color: #999999;
}

 

.input-icon .input::-ms-input-placeholder {
  color: #999999;
}
.w-input, .w-select {
    width: 100%;
    height: 38px;
    color: #333;
    vertical-align: middle;
    background-color: #fff;
    border: 1px solid #ccc;
    margin-bottom: 10px;
    padding: 8px 12px;
    font-size: 14px;
    line-height: 1.42857;
    display: block;
}
.icon.secondary {
    color: #ff6633;
    background-color: transparent;
    font-weight: normal;
    font-size: 20px;
}
.icon.form {
    position: absolute;
    bottom: 50%;
    right: 1em;
    transform: translateY(50%);
}
.form {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
}
.secondary {
    background-color: #ff6f51;
}
.icon {
    background-color: transparent;
    flex: 0 auto;
    font-family: Font Awesome,sans-serif;
    font-size: 1em;
    font-weight: 400;
    text-decoration: none;
    position: static;
    top: auto;
    bottom: 0%;
    left: auto;
    right: 0%;
}
.filter-block.border-top-bottom {
    border-top-color: #eeded9;
    border-bottom-color: #eeded9;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
}
.flex-h.spaced {
    justify-content: space-between;
}
.flex-h {
    display: flex;
}
.border-top-bottom {
    border-top: 2px solid #000;
    border-bottom: 2px solid #000;
}
.filter_results-text.marge-bottom {
    margin-bottom: 0.5em;
    color: #2a2b2c;
}
.filter_results-text {
    font-size: .887em;
    font-weight: 600;
    line-height: 20px;
}
.text-color-orange {
    color: #ff6633;
}
.checkbox_input[type="checkbox"] {
    accent-color: #9966cc;
}
.filter_reset-all {
    align-self: start;
    padding-bottom: 0.2rem;
    text-decoration: underline;
    color: #9966cc;
    font-weight: 600;
}
.smaller.primary.marge-bottom.less {
    margin-bottom: 1em;
}
.w-checkbox:before {
    /* content: " "; */
    grid-area: 1/1/2/2;
    display: table;
}
.checkbox_input {
    width: 1.25rem;
    height: 1.25rem;
    border: 2px solid #cdbeba;
    border-radius: 4px;
    margin-top: 0;
    margin-left: 0;
    margin-right: 0.75rem;
    vertical-align: text-bottom;
}
.w-checkbox-input {
    float: left;
    margin: 4px 0 0 -20px;
    line-height: normal;
}
.equal_cards {
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
} 
.equal_cards.min-cards .card .card-body {
    padding: 1em;
}
 
.equal_cards.min-cards .card {
    height: auto !important;
}

@media screen and (min-width: 1440px) {
.smaller.marge-bottom {
    margin-bottom: 1em;
}
}

.filter-block .container {
    flex: 1;
    margin-left: 0;
    margin-right: 0;
    position: relative;
    padding: 0;
}
ul.ks-cboxtags {
    list-style: none;
}
ul.ks-cboxtags li{
  display: inline;
  margin-right: 5px;
}
ul.ks-cboxtags li label{
    display: inline-block;
    border-radius: 25px;
    white-space: nowrap;
    margin: 3px 0px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    transition: all .2s;
    font-weight: 500;
    padding: 8px 12px;
    cursor: pointer;
    background-color: #d6c2eb;
    border: 2px solid #d6c2eb;
    color: #2a2b2c;
}
ul.ks-cboxtags li input[type="checkbox"]:checked + label::before {
    transform: rotate(-360deg);
    transition: transform .3s ease-in-out;
}
ul.ks-cboxtags li input[type="checkbox"]:checked + label {
    transition: all .2s;
    border: 2px solid #000;
    background-color: #d6c2eb;
    color: #2a2b2c;
}
ul.ks-cboxtags li input[type="checkbox"] {
  position: absolute;
  opacity: 0;
}
// ul.ks-cboxtags li input[type="checkbox"]:focus + label {
//   border: 2px solid #000;
// }

@media (min-width: 1200px) {
    .container.blogpage_tab {
        max-width: 1260px;
    }
}

@media #{$information-large} {
    .blog_page .fixed_blog_height {
        height: 420px !important;
    }
    .blog_details_page .fixed_blog_height {
        height: 400px !important;
    }
    .card-img-top {
        //height: 200px !important;
    }
}
@media #{$information-desktop} {
    .blog_page .fixed_blog_height {
        height: 458px !important;
    }
    .blog_details_page .fixed_blog_height {
        height: 458px !important;
    }
    .card-img-top {
        //height: 200px !important;
    }
}
@media #{$information-tablet} {
    .related .p-l-0,
    .related .p-r-0 {
        padding: 0;
        margin-bottom: 15px;
    }
    .blog_page .fixed_blog_height {
        height: 440px !important;
    }
    .card-img-top {
        //height: 200px !important;
    }
}
@media #{$information-phone} {
    .mobile-view-blog {
        display: block;
    }
    .desktop-view-blog {
        display: none;
    }
    .blog_page .fixed_blog_height {
        height: auto !important;
    }
    .card-img-top {
        height: auto;
    }
    .related .p-l-0,
    .related .p-r-0 {
        padding: 0;
        margin-bottom: 15px;
    }
    .blog_details_container .font-37 {
        font-size: 16px !important;
    }

    .blog_page .banner_blog {
        background: url(/assets/images/blog_bg.png);
        background-repeat: no-repeat;
        background-size: cover;
    }
    .text-left {
        text-align: left !important;
    }
}
.blogpage_tab #blogSearch ul.ks-cboxtags li label {
    padding: 8px 30px;
 }
