@include fontFace('MyriadPro-Light','../fonts/MyriadPro-Light/MyriadPro-Light');
@include fontFace('MyriadPro-Regular','../fonts/MyriadPro-Regular/MyriadPro-Regular');
@include fontFace('segoeui','../fonts/segoeui/segoeui');
@include fontFace('SegoeUI-Bold','../fonts/SegoeUI-Bold/SegoeUI-Bold');
@include fontFace('segoeuib','../fonts/segoeuib/segoeuib');
@include fontFace('segoeuil','../fonts/segoeuil/segoeuil');
@include fontFace('Gotham-bold','../fonts/Gotham-Bold/Gotham-Bold');
@include fontFace('Gotham-medium','../fonts/Gotham-Medium/Gotham-Medium');
@include fontFace('Gotham-book','../fonts/Gotham-Book/Gotham-Book');
@include fontFace('seguisb','../fonts/seguisb/seguisb');
@include fontFace('Mblack','../fonts/Mblack/Montserrat-Black');
@include fontFace('Mbold','../fonts/Mbold/Montserrat-Bold');
@include fontFace('Mlite','../fonts/Mlite/Montserrat-Light');
@include fontFace('Mmedium','../fonts/Mmedium/Montserrat-Medium');
@include fontFace('Mregular','../fonts/Mregular/Montserrat-Regular');
@include fontFace('Msemibold','../fonts/Msemibold/Montserrat-SemiBold');
@include fontFace('Mthin','../fonts/Mthin/Montserrat-Thin');
@include fontFace('Qbold','../fonts/Qbold/Quicksand-Bold');
@include fontFace('Qlite','../fonts/Qlite/Quicksand-Light');
@include fontFace('Qmedium','../fonts/Qmedium/Quicksand-Medium');
@include fontFace('Qregular','../fonts/Qregular/Quicksand-Regular');
@include fontFace('Rregular','../fonts/Rregular/Roboto-Regular');
@include fontFace('SFregular','../fonts/SFNSDisplay/SFNSDisplay');
@include fontFace('Iregular','../fonts/Iregular/Inter-Regular');
@include fontFace('Imedium','../fonts/Imedium/Inter-Medium');


