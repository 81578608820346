.Account-bg {
    background: url(/assets/images/banner_accounts.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    //  height:383px;
    height: 200px;
}

.avatar-preview img {
    width: 96px;
    height: 96px;
}

.abbey-txt {
    color: $Abbey;
}

.cond-btn {
    border-radius: 8px !important;
    border: 0;
    text-align: left;
    width: 203px;
    height: 48px;
    padding: 0 10px;
    margin-bottom: 10px;
    font-size: 0.9375rem !important;
    margin-right: 10px;
    color: $White;
    position: relative;
}

.account_tabs {
    .nav-item {
        width: 45%;
    }

    .nav-link {
        color: $Aluminium;
        padding-bottom: 3px;
        border-radius: 0;
        padding-left: 2px;
        padding-right: 0;
    }

    .nav-link.active {
        border-bottom: 2px solid $OutrageousOrange;
        background: $White;
        color: $Shark;
        border-left: none;
        border-right: none;
        border-top: none;
    }

    .upload-btn {
        color: $TurquoiseBlue;
        float: left;
        width: 100%;
        margin-top: 10px;
    }

    .save-btn {
        padding-top: 10px;
        padding-bottom: 10px;
        font-size: 0.8rem;
    }

    .cond-btn {
        img {
            position: absolute;
            padding: 9px 82px;
            top: 0;
            left: 1px;
            background: rgba(0, 0, 0, 0.38);
            width: auto;
            height: 48px;
            background-position: center;
            border-radius: 8px;
        }
    }

    .cond1 {
        background: $Sycamore;
    }

    .cond2 {
        background: $Paarl;
    }

    .cond3 {
        background: $TurquoiseBlue;
    }

    .cond4 {
        background: $Lavender;
    }

    .cond5 {
        background: $Lochmara;
    }

    .cond6 {
        background: $IceCold;
    }

    .cond7 {
        background: $Kournikova;
    }

    .cond8 {
        background: $BurningOrange;
    }

    .member-bor {
        border-radius: 1rem;
        padding-top: 15px;
        padding-bottom: 15px;
    }
}

.account_tabs .member-bor {
    width: 36%;
}

.pricing-popup .pricing>div {
    float: left;
    width: 25%;
    margin-bottom: 20px;
}

.pricing-popup {
    width: 600px !important;
    max-width: 600px !important;
}

.pricing-popup img {
    width: 130px;
}

.pricing-popup .card-price {
    font-size: 11px !important;
}

.pricing-popup .card {
    margin-right: 13px;
    height: 100%;
}

.pricing-popup .modal-dialog.modal-width--custom {
    width: 600px;
    max-width: 600px;
    float: left;
}

.pricing-desktop .slick-slider {
    width: 100% !important;
}

.pricing-desktop img {
    width: 130px;
    margin: 0 auto;
}

p.occupied_empty {
    padding-bottom: 14px;
}

.pricing-desktop .slick-arrow {
    display: block !important;
}

.pricing-desktop .slick-prev {
    left: -57px;
}

.pricing-desktop .slick-next {
    right: -57px;
}

.pricing-desktop {
    margin-bottom: 20px;
}

.pricing-desktop .card {
    margin-right: 13px;
    height: 485px;
}

.pricing-popup {
    width: 800px !important;
    max-width: 800px !important;
}

.grid-four-pricing {
    width: 800px !important;
    max-width: 800px !important;
    display: table;
    float: none !important;
    margin: 0 auto !important;
}

.pricing-mobile {
    display: none;
}

.pricing-desktop .card {
    margin-right: 13px;
    height: auto;
}

.formobile {
    display: none;
}

/*--------------------------------------------------*/
.New_account_details {
    .Account-bg {
        height: 255px;
    }

    .tab_group {
        margin-top: -31px;

        .tab_full {
            z-index: 1;

            .tab_ul {
                border: 1px solid $Mercury;
                background: #fff;
                border-radius: 5px;
                padding: 4px;
                display: flex;
                align-items: center;

                .nav-item {
                    border-right: 1px solid $Mercury;
                    padding: 0px 5px;
                    margin: 0;

                    .blue_line {
                        display: none;
                    }

                    .nav-link {
                        border: none;
                        color: #A5A6A9;
                        font-size: 15px !important;
                    }

                    .nav-link.active {
                        // border-bottom: 4px solid $TurquoiseBlue;
                        border-top: 0;
                        border-right: 0;
                        border-left: 0;
                        position: relative;
                        // top: 4px;
                        color: #2b353f;

                        .blue_line {
                            display: inline-block;
                            width: 61px;
                            border-bottom: 4px solid #71D6E6;
                            position: absolute;
                            left: 0;
                            right: 0;
                            margin: auto;
                            top: 100%;
                        }
                    }
                }

                .nav-item:last-child {
                    border-right: 0;
                }
            }
        }

        li.nav-item a.nav-link.font-semibold.active::after {
            content: none;
        }
    }

    .border-right {
        border-right: 2px solid #a5a6a9 !important;
        margin-right: 30px;
        padding-right: 30px;
    }

    .account_Tabs_new {
        .profile_img {
            width: 75px;
            height: 75px;
            display: block;
            margin: auto;
            border-radius: 100%;
            border: 2px solid gray;

            img {
                width: 100%;
                border-radius: 100%;
                filter: grayscale(100%);
                height: 100%;
            }
        }

        .profile_name {
            color: #A5A6A9;
            font-size: 14px;
            font-weight: 600;
            letter-spacing: 0.01px;
            line-height: 22px;
            display: block;
            text-align: center;
            margin: 8px 0 0 0;
            word-break: break-all;
        }

        .profile_img.plus_profile_img {
            width: 75px;
            height: 75px;
            display: block;
            margin: auto;
            border-radius: 100%;
            border: 2px solid gray;
            text-align: center;
            align-items: center;
            line-height: 75px;
            font-size: 30px;
            color: gray;
        }

        .nav-item {
            width: 100% !important;
        }

        .nav-link {
            margin: 0 !important;
            border: none;
            background: transparent;
            width: 100%;
        }

        .nav-link.active {
            border-bottom: none;
            background: transparent !important;
            border-left: none;
            border-right: none;
            border-top: none;

            .profile_img {
                border: 2px solid $TurquoiseBlue;
            }

            .profile_name {
                color: #2A2B2C;
            }

            span.profile_img {
                img {
                    filter: grayscale(0);
                }
            }
        }

        &.child_prof_reg {
            .profile_name {
                margin: 8px 0 8px 0;
                height: 25px;
            }

            .profile_img,
            .profile_img.plus_profile_img {
                width: 100px;
                height: 100px;
                line-height: 100px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 35px;
            }

            .nav-item {
                width: auto !important;
            }

        }

        &.border-0 {
            border: none !important;
            justify-content: center;
            margin: 0 auto;
        }
    }
}

.profile_account_tabs {
    ul#myTab {
        flex-direction: row !important;
        flex-wrap: nowrap;
        width: 100%;
        text-align: center;
        justify-content: center;
    }

    &.new_prof_popup {
        ul#myTab {
            flex-wrap: wrap !important;
            max-height: 290px;
            overflow-x: hidden;
            overflow-y: auto;

            .profile_img {
                img {
                    height: 100%;
                }
            }
        }
    }

    .profile_img {
        width: 100px;
        height: 100px;
        display: block;
        margin: auto;
        border-radius: 100%;
        border: 2px solid gray;

        img {
            width: 100%;
            border-radius: 100%;
            // filter: grayscale(100%);
        }
    }

    .profile_name {
        color: #A5A6A9;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0.01px;
        line-height: 22px;
        display: block;
        text-align: center;
        margin: 8px 0 0 0;
    }

    .nav-item {
        width: auto !important;
        margin-right: 35px;
    }

    .nav-link {
        margin: 0 !important;
        border: none;
        background-color: transparent;
    }

    .nav-link.active,
    .nav-link:hover {
        border-bottom: none;
        background: transparent !important;
        border-left: none;
        border-right: none;
        border-top: none;

        .profile_img {
            border: 2px solid $TurquoiseBlue;
        }

        .profile_name {
            color: #2A2B2C;
        }

    }

}

/*---------------------------------------------------*/
@media #{$information-xtralarge} {
    .pricing-popup {
        width: 1000px !important;
        max-width: 1000px !important;
    }

    .grid-four-pricing {
        width: 1000px !important;
        max-width: 1000px !important;
        display: table;
        float: none !important;
        margin: 0 auto !important;
    }

    .formobile {
        display: none;
    }

    .membership-popup .modal-dialog {
        width: 100% !important;
        max-width: 100% !important;
    }
}

@media #{$information-large} {
    .Account-bg {
        height: auto !important;
    }

    .account_tabs .member-bor {
        width: 50%;
    }

    .formobile {
        display: none;
    }
}

@media #{$information-desktop} {
    .account_tabs .nav-item {
        width: 100%;
        text-align: left;
        border: 0;
    }

    .account_tabs .member-bor {
        width: 100%;
    }

    .membership-popup .modal-dialog {
        width: 100% !important;
        max-width: 100% !important;
    }

    .formobile {
        display: none;
    }
}

@media #{$information-tablet} {
    .pricing-popup .pricing>div {
        float: left;
        width: 50%;
        margin-bottom: 20px;
    }

    .account_tabs .container-fluid {
        width: 100% !important;
    }
}

@media #{$information-phone} {
    .pricing-mobile {
        display: block;
    }

    .pricing-desktop {
        display: none;
    }

    .pricing-popup .modal-dialog.modal-width--custom {
        width: 100% !important;
        max-width: 100% !important;
        float: left;
        display: block !important;
    }

    .pricing-mobile ul.slick-dots {
        margin-bottom: 30px;
    }

    .pricing-mobile .slick-slide img {
        display: table;
        margin: 0 auto;
    }

    .pricing-popup .pricing>div {
        float: left;
        width: 100%;
        margin-bottom: 20px;
    }

    .pricing-popup {
        width: 100% !important;
        max-width: 100% !important;
    }

    .account_tabs {
        .nav-item {
            width: 100%;
        }
    }

    .text-new {
        text-align: center;
    }

    .cond-btn {
        width: 100%;
        text-align: center;
    }

    .account_tabs .cond-btn img {
        position: absolute;
        top: 0;
        left: 50%;
        background: none;
        width: 200px;
        height: auto;
        background-position: center;
        right: auto;
        margin-left: -98px;
    }

    .Account_details .member-txt {
        font-size: 19px !important;
    }

    .account_tabs .save-btn {
        width: 100%;
    }

    .Account-bg {
        height: auto !important;
        background-size: cover;
    }

    .account_tabs .container-fluid {
        width: 75% !important;
    }

    .fordesktop {
        display: none;
    }

    .formobile {
        display: block;
    }
}
