.progress-page {
  .stand-table .table-bordered {
    border: 2px solid #0073c2;
  }

  .tbody-row {
    border-bottom: 2px solid #0073c2 !important;
    padding: 0.73rem !important;
  }

  .thead-table-data {
    border-right: 2px solid #ffffff7a !important;
  }

  .tbody-table-datacount {
    border-left: 2px solid #0073c2 !important;
  }

  .stand-table thead {
    background-color: #0073c2;
    color: #fff;
  }

  .text-blue {
    color: #0073c2;
  }

  .stand-table .table td.py-10 {
    padding-top: 0.656rem;
    padding-bottom: 0.656rem;
  }

  .table.table.table-bordered:last-child {
    position: relative;
  }

  // .table.table.table-bordered:after {
  //   position: absolute;
  //   content: "";
  //   background-image: url(/assets/images/tropy.png);
  //   width: 53px;
  //   height: 53px;
  //   background-repeat: no-repeat;
  //   background-size: contain;
  //   bottom: 8px;
  //   right: -27px;
  // }

  .tbody-table-datacount {
    position: relative;
  }

  .trophy-size {
    position: absolute;
    left: 94%;
    height: 53px;
    width: 53px !important;
  }
}

.bird-size {
  position: absolute;
  right: -54px;
  transform: translate(-50%, -50%);
  bottom: 50px;
  height: 74px;
  width: 47px;
}

.score-title {
  margin-top: 55px !important;
  margin-bottom: -25px !important;
  margin-left: 25px !important;
}

@media only screen and (min-width: 424px) and (max-width: 767px) {
  .progress-page .trophy-size {
    left: 93%;
    height: 45px;
  }
}

@media screen and (max-width: 767px) {
  .progress-page .trophy-size {
    left: 92%;
    height: 45px;
    bottom: 0px;
  }

  .bird-size {
    right: -47px;
    width: 37px;
    bottom: 55px;
    height: 45px;
  }

  .video-react .video-react-big-play-button:before {
    background-size: 45px 40px !important;
    display: inline-block;
    width: 253px !important;
    height: 237px !important;
  }

  .score-title {
    margin-top: 5px !important;
  }
}

@media (max-width: 320px) {
  .progress-page .trophy-size {
    left: 88%;
    bottom: 20px;
  }
}

@media (min-width: 768px) and (max-width:1023px) {
  .progress-page .trophy-size {
    left: 85%;
    bottom: 10px;
  }
}

@media (min-width: 540px) and (max-width:766px) {
  .progress-page .trophy-size {
    left: 94%;
  }
}

@media (min-width: 1025px) and (max-width: 1280px) {
  .progress-page .trophy-size {
    left: 92%;
  }

  .bird-size {
    bottom: 35px !important;
  }
}

@media (min-width:2560px) {
  .progress-page .trophy-size {
    left: 96.5% !important;
    bottom: 0px;
  }
}

@media screen and (min-width: 1311px) and (max-width: 2559px) {
  .progress-page .trophy-size {
    left: 94%;
    bottom: 15px;
    top: 0px;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1310px) {
  .progress-page .trophy-size {
    left: 91%;
    height: 45px;
    bottom: 0px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .welcome-test-popup {
    .modal-content {
      width: auto !important;
      right: 0px !important;
    }
  }
}

@media (min-width:2000) and (max-width:2560px) {
  .bird-size {
    bottom: 15px !important;
  }
}

.underline-text {
  text-decoration: underline;
}

.test-question {
  height: 45px;
  width: 664px;
  color: #FFFFFF;
  font-family: "Montserrat";
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0.01px;
  line-height: 28px;
}

.test-heading {
  margin-left: 12px;
  font-size: 25px;
  font-weight: 500;
}

.fitness-popup .bold-text {
  font-weight: 600;
  font-size: 24px !important;
  font-family: "Msemibold" !important;

}

.styles_modal__gNwvD {
  max-width: 85% !important;
}

.fitness-popup .form-group.field.field-integer {
  padding-top: 30px !important;
}


@media (max-width: 1400px) {
  .fitness-popup .bold-text {
    font-size: 24x !important;
  }
}

@media (max-width: 1024px) {
  .fitness-popup .bold-text {
    font-size: 24px !important;
  }
}

@media (min-width: 1400px) and (max-width: 2560px) {
  .fitness-popup .bold-text {
    font-size: 24px !important;
  }

  .video-react .video-react-big-play-button:before {
    position: absolute;
    top: -15px;
    left: -15px;
    width: 100%;
    height: 100%;
  }
}

@media (min-width: 768px) and (max-width: 2560px) {
  .video-react .video-react-big-play-button:before {
    position: absolute;
    top: -15px !important;
    left: -15px !important;
  }

  .fitness-form .or-text {
    margin-left: 35px;
  }
}

@media (min-width: 320px) and (max-width: 425px) {
  .video-react .video-react-big-play-button:before {
    position: absolute;
    top: -3px !important;
    left: -3px !important;
  }

}

.video-react .video-react-poster {
  background-size: cover !important;
}

.fitness-form .btn-info {
  display: none;
}


@media (min-width: 320px) and (max-width: 767px) {
  .fitness-form .or-text {
    margin-left: 20px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .welcome-test-popup {
    .modal-content {
      width: auto !important;
      right: 0px !important;
    }
  }
}

