    .container {
    }
    .input {
        height: 3.2em;
        color: #2f0b33;
        border: 2px solid #cdbeba;
        border-radius: 500px;
        margin-bottom: 0;
        padding: 0.5em 1.2em;
        font-size: 1em;
        font-weight: 600;
        line-height: 1.4em;
    }
    .suggestionsContainer {
    }
    .suggestion {
    }
    .suggestionHighlighted {
    }
