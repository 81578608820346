// primary footer
.first-section {
  .btn-facebook, .btn-google {
    font-size: 1.5rem;
  }

  .btn-facebook i, .btn-google i {
    font-size: 2.7rem;

    vertical-align: middle;
    padding-right: 10px;
    top: 0 !important;
    left: 0 !important;
  }

  .btn-login {
    background: $TurquoiseBlue;
    border-radius: 5px;
    letter-spacing: 0.1rem;
    padding: 0.4rem 1rem;
    color: #fff !important;
    height: 61px;
    text-align: left;
    font-size: 1.5rem;

    margin: 0 auto;
  }

  .btn-facebook {
    background: #4266b7;
    font-size: 1.5rem;
    border-radius: 5px;
    letter-spacing: 0.1rem;
    padding: 0.4rem 1rem;
    transition: all 0.2s;

    color: #fff !important;
    height: 61px;
    text-align: left;
  }

  .btn-google {
    background: #fff;
    font-size: 1.5rem;
    border-radius: 5px;
    letter-spacing: 0.1rem;
    padding: 0.4rem 1rem;
    transition: all 0.2s;

    border: 1px solid rgb(78, 77, 77);
    color: #3b4045 !important;
    height: 61px;
    text-align: left;
  }

  .fa-google {
    background: conic-gradient(from -45deg, #ea4335 110deg, #4285f4 90deg 180deg, #34a853 180deg 270deg, #fbbc05 270deg) 73% 55%/150% 150% no-repeat;
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    -webkit-text-fill-color: transparent;
  }

  .btn-login i {
    font-size: 2.4rem;
    padding-right: 10px;
    vertical-align: middle;
  }

  span.signup-text {
    vertical-align: sub;
  }
  .btn-google{
    text-align: center;
  }
  .btn-takeme {
    background: #4266b7;
    border-radius: 5px;
    padding: 0.4rem 1rem;
    transition: all 0.2s;
    color: #fff !important;
    height: 61px;
    @extend %Qbold;
  }
}

.close-register {
  position: absolute;
  right: 1em;
  font-size: 20px;
  top: 0.4em;
}

.close_back_icon_align {
  .close-register {
    right: 2em !important;
    top: 25px !important;
    cursor: pointer;
  }

  .arrow-img {
    left: 2em !important;
    top: 26px !important;
  }
}

.btn-gender {
  height: 38px;
  line-height: 26px;
}

.css-3rj0br-ReactDropdownSelect span {
  vertical-align: middle;
  margin-top: 11px;
  padding-left: 10px;
}

#register-box input {
  min-height: 48px;
}

#register-box .radio input[type="radio"]+.radio-label:before {
  width: 1.5rem;
  height: 1.5rem;
}

#register-box select {
  text-indent: 1px;
  text-overflow: "";
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 3px 3px 3px 11px;
  border: 1px solid $Alto;
  background: transparent url(/assets/images/select.png) no-repeat;
  height: 48px;
  width: 100%;
  background-position: 96%;
  margin: 0 auto;
  display: table;
  color: $Shark;
  font-size: 14px;
  @extend %Qregular;
}

.con-btn {
  height: 67px;
  color: #fff !important;
  font-size: 0.8rem;
  border-radius: 8px !important;
  border: 0;
  text-align: left;
  width: 100%;
  padding: 5px 16px;
}

.con1 {
  background: $TurquoiseBlue;
}

.con2 {
  background: $OutrageousOrange;
}

.con-btn img {
  width: 25px;
  margin-right: 10px;
}

.video-tag1 img {
  width: 200px;
  margin-top: 35px;
  margin-bottom: 30px;
}

.video-tag2 img {
  width: 200px;
  margin-bottom: 40px;
}

#register {
  background: url(/assets/images/registerpagebg.jpg);
  //height: 100vh;
  background-size: 100% 100%;

  &.register_height {
    min-height: 100vh;
    height: 100%;
  }

  .register_logo {
    border-bottom: 1px solid $Concrete;
  }

  // h3 {
  //   color: $OutrageousOrange;
  //   background: $Sazerac;
  //   font-size: 1.5rem;
  //   border-top-left-radius: 5px;
  //   border-top-right-radius: 5px;
  //   height: 103px;
  //   padding-top: 30px;
  //   @extend %Msemibold;
  //   overflow: hidden;
  //   padding-right: 45px;
  //   padding-left: 45px;
  // }
  &.background-none {
    background: none !important;
  }

  h3 {
    color: $OutrageousOrange;
    background: $Sazerac;
    font-size: 1.5rem;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    overflow: hidden;
    padding: 20px 45px;
  }

  .set-offset {
    margin-left: 50px;
  }

  #register-form {
    border: 1px solid $Alto;
    border-radius: 5px;
    margin-bottom: 2rem;
    background: $White;

    .text-label {
      color: $Shark;
      font-size: 1rem;
      @extend %Qmedium;
    }

    &.border_none {
      border: none !important;
      margin-bottom: 0 !important;

      .arrow-img {
        left: 30px !important;
        top: 25px !important;
      }

      .close-register {
        right: 30px !important;
        top: 20px !important;
      }
    }

    a:hover {
      text-decoration: none;
    }
  }
  .close-register {
    margin: 10px 10px 0 0;
  }
  .arrow-img {
    margin: 10px 0 0 10px;
  }
}

#register-link .bluebtn {
  font-size: 14px;
  //height: 20px;
  vertical-align: middle;
  line-height: 27px;
  width: 72% !important;
  margin: 0 auto;
  display: table;
  float: none !important;
}

#register-link .btn-blue-inverse {
  font-size: 14px;
  height: 38px;
  vertical-align: middle;
  line-height: 27px;
  width: 72% !important;
  margin: 0 auto;
  display: table;
  float: none !important;
}

.thanksimg img {
  width: 73%;
}

#register-form .pricing p {
  color: #53565a;
  font-size: 0.75rem;
}

#register-link .btn-blue-inverse {
  background: #fff !important;
  color: $Turquoise;
  font-size: 14px;
  padding: 0.342rem 1.1rem;
  border-radius: 2rem !important;
  border: 1px solid $Turquoise;
  text-decoration: none !important;
  height: 38px;
  line-height: 27px;
}

#register-form .arrow-img {
  position: absolute;
  left: 1em;
  top: 0.4em;
}

/*toggle*/

.switch {
  display: inline-block;
  height: 20px;
  position: relative;
  width: 46px;
  vertical-align: middle;

  input {
    display: none;
  }
}

.slider {
  background-color: $Silver;
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.4s;

  &:before {
    background-color: $White;
    bottom: 3px;
    content: "";
    height: 15px;
    left: 2px;
    position: absolute;
    transition: 0.4s;
    width: 15px;
  }
}

input {
  &:checked {
    &+.slider {
      // background-color: $Fern;
      background-color: $Turquoise;

      &:before {
        transform: translateX(26px);
      }
    }
  }
}

.slider.round {
  border-radius: 34px;

  &:before {
    border-radius: 50%;
  }
}

/*radio-btn*/

.radio {
  margin: 0;
  line-height: 16px !important;

  input[type="radio"] {
    position: absolute;
    opacity: 0;

    +.radio-label {
      &:before {
        content: "";
        background: $WildSand;
        border-radius: 100%;
        border: 1px solid darken($WildSand, 25%);
        display: inline-block;
        width: 0.8em;
        height: 0.8em;
        position: relative;
        top: -0.2em;
        margin-right: 1em;
        vertical-align: top;
        cursor: pointer;
        text-align: center;
        transition: all 250ms ease;
      }
    }

    &:checked {
      +.radio-label {
        &:before {
          background-color: $Orange;
          box-shadow: inset 0 0 0 4px $WildSand;
        }
      }
    }

    &:focus {
      +.radio-label {
        &:before {
          outline: none;
          border-color: $Orange;
        }
      }
    }

    &:disabled {
      +.radio-label {
        &:before {
          box-shadow: inset 0 0 0 4px $WildSand;
          border-color: darken($WildSand, 25%);
          background: darken($WildSand, 25%);
        }
      }
    }

    +.radio-label {
      &:empty {
        &:before {
          margin-right: 0;
        }
      }
    }
  }
}

/* 	.step1 {
      display: none;
  }
  .step2 {
      display: none;
  }
  .step3 {
      display: none;
  }
  .step4 {
      display: none;
  }
  .step5 {
      display: none;
  }
  .step6 {
      display: none;
  }
  .step7 {
      display: none;
  }
  .step8 {
    display: none;
  }
  .step9 {
      display: none;
  }
  .step10 {
      display: none;
  }
  .step11 {
      display: none;
  }
  .step12 {
      display: none;
  }
  .step13 {
      display: none;
  }
  .step14 {
      display: none;
  }
  .step15 {
      display: none;
  }
  */
.video-tag iframe {
  border: 0;
  border-radius: 0.3rem;
  width: 100%;
}

.brown-btn img {
  position: absolute;
  right: 85px;
  top: 7px;
}

.card-details--page {
  .input-control {
    border-radius: 0.2rem !important;
  }

  .postal-code {
    padding: 0;
  }
}

.logo-size {
  //width:200px;
  width: 125px;
}

.logo-size-member-content {
  //width:200px;
  width: 125px;
  margin: 0px 50px;
}

// media query
@media #{$information-large} {
  .first-section .btn-facebook, .first-section .btn-google {
    text-align: center;
    width: 100%;
    margin: 0 auto;
  }

  .first-section .btn-login {
    text-align: center;
    width: 100%;
    margin: 0 auto;
  }
  .register-heading {
    margin-top: 50px;
  }
}

@media #{$information-tablet} {
  .input_section {
    flex: 0 0 90% !important;
    max-width: 90% !important;
    padding: 1rem !important;
  }

  .postal-code {
    padding: 0 15px;
  }
}

@media #{$information-phone} {
  .input_section {
    flex: 0 0 90% !important;
    max-width: 90% !important;
    padding: 1rem !important;
  }

  .exp-date {
    margin-bottom: 10px;
    padding: 0;
  }

  #register h3 {
    height: auto;
    font-size: 1.3rem;
  }

  #register .set-offset {
    margin-left: 0px;
  }

  span.signup-text {
    font-size: 10px;
    font-family: MSemibold;
  }
  .register-heading {
    margin-top: 10px;
  }
  #register-form .react-autosuggest__suggestions-container{
    width:100% !important;
    margin:0px !important;
  }
}

.loading_icon .lds-default {
  display: inline-block;
  position: absolute;
  width: 80px;
  height: 80px;
  left: 0;
  right: 0;
  margin: auto;
}

.popup_close_align button.close-register.orangefont.close_btns {
  background: transparent;
  border: none;
  top: 16px;
  right: 31px;
}

.popup_close_align a.close-register.orangefont {
  display: none;
}

#register-form .react-autosuggest__input {
    border-radius: .25rem;
    height: 48px;
    border: 1px solid #dbdbdb;
    font-size: 14px;

}

#register-form .react-autosuggest__suggestions-container {
    margin-left: 0;
    width: 100%;
    border-radius: 0.25rem;
    display: block;
    float: left;
    margin-bottom: 20px;
    position: unset;

}

input.react-autosuggest__input {
  width: 100%;
  padding: 0px 12px;
}

.react-autosuggest__suggestions-container {
  width: 100%;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  height: auto;
  max-height: 300px;
  overflow-y: auto;
  background: #fff;
  display: block;
  border: 1px solid #dbdbdb;
  padding: 0px;
  font-family: Qregular;
  font-size: 14px !important;
  border-color: #dbdbdb;
  margin-left: 12px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  letter-spacing: 0.01px;
  line-height: 22px;
}

.react-autosuggest__suggestions-container--open::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.react-autosuggest__suggestions-container--open::-webkit-scrollbar-track {
  background: #ebebeb;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

.react-autosuggest__suggestions-container--open::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 8px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}
