%MyriadPro-Light{  font-family: $MyriadPro-Light;}
%MyriadPro-Regular{  font-family:$MyriadPro-Regular;}
%segoeuil{  font-family: $segoeuil;}
%segoeui{  font-family:$segoeui;}
%seguisb{ font-family: $seguisb;}
%segoeuib{  font-family: $segoeuib;}
%SegoeUI-Bold{  font-family: $SegoeUI-Bold;}
%nonshadow{  box-shadow: none !important;}
%h100{height:100% !important;}
%fontawesome{font-family: $fontawesome;}
%simple{font-family:$simple;}
%Gotham-book{font-family:$Gotham-book;}
%Gotham-medium{font-family:$Gotham-medium;}
%Gotham-bold{font-family:$Gotham-bold;}
%Mblack{font-family:$Mblack;}
%Mbold{font-family:$Mbold;}
%Mlite{font-family:$Mlite;}
%Mregular{font-family:$Mregular;}
%Mmedium{font-family:$Mmedium;}
%Msemibold{font-family:$Msemibold;}
%Mthin{font-family:$Mthin;}
%Qbold{font-family:$Qbold;}
%Qlite{font-family:$Qlite;}
%Qmedium{font-family:$Qmedium;}
%Qregular{font-family:$Qregular;}
%Rregular{font-family:$Rregular;}
%SFregular{font-family:$SFregular;}