.condemand {
    .banner_demand {
        background: url(/assets/images/banner_condition.png);
        background-size: cover;
        background-repeat: no-repeat;
        //height:383px;
        background-position: center;
    }

    .close-btn {
        padding: 0.2rem 2.8rem !important;
    }

    .filter_head {
        border-bottom: 1px solid $Bombay;
        padding-bottom: 5px;
        margin-bottom: 20px;
        @extend %SFregular;
        font-size: 1rem;
        font-weight: normal;
        color: $Shark;
    }

    .filter_grid p {
        @extend %SFregular;
        font-size: 0.875rem;
        color: $Shark;
    }

    .filter_grid .input-range {
        height: 1rem;
        position: relative;
        width: 90%;
        display: table;
        margin: 0 auto;
    }

    .tab_group {
        margin-top: -38px;

        .tab_full {
            z-index: 1;

            .tab_ul {
                border: 1px solid $Mercury;
                background: #fff;
                border-radius: 5px;
                padding: 5px;

                .nav-item {
                    border-right: 1px solid $Mercury;
                    padding: 0px 36px;

                    .nav-link {
                        color: $Aluminium;
                        border: none;
                        font-size: 0.7rem;
                    }

                    .nav-link.active {
                        border-bottom: 4px solid $TurquoiseBlue;
                        border-top: 0;
                        border-right: 0;
                        border-left: 0;
                        position: relative;
                        top: 4px;
                        color: $Black;
                    }
                }

                .nav-item:last-child {
                    border-right: 0;
                }
            }
        }
    }

    .search-btn {
        .form-control {
            border-top-left-radius: 20px;
            border-bottom-left-radius: 20px;
            border-color: $Alto;
            height: 33px;
            font-size: 0.7rem;
        }

        .form-control::placeholder {
            color: $Silver;
        }

        .input-group-append .btn {
            border-top-right-radius: 20px;
            border-bottom-right-radius: 20px;
            background: $TurquoiseBlue;
            width: 140px;
            border-color: $TurquoiseBlue;
            height: 33px;
            font-size: 0.9rem;
            line-height: 16px;
        }
    }

    .four_grid {
        .card-body {
            padding: 0.5rem;
        }

        .left-content {
            width: 190px;
            margin-top: 7px;
        }

        .extra-small-txt {
            font-size: 0.8125rem !important;
        }
    }

    .on-liveclasses {
        background: $Magnolia;
        margin-top: -20px;
        z-index: -1px;

        //height: 376px;
        .list_section {
            background: #fff;

            .item.list-group-item {
                border: 0;
            }
        }
        &.banner-border {
            position: relative;
            &:before {
                content:"";
                position: absolute;
                bottom: 7px;
                border-bottom: 2px solid #71D6E6;
                left: 0;
                right: 0;
                z-index: 1;
            }
        }
    }

    .workout-overlay.vid_time {
        bottom: 0 !important;
        z-index: 1;
        top: 0 !important;
        width: 100%;
        left: 0;
        right: 0;
        margin: auto;
        text-align: center;
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;
        border-radius: 0px;
    }
}

.btn-quicklink {
    color: white;
    font-size: 16px;
    height: 34px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 10px;
}

.schedule_block {
    width: 550px;
    max-width: 550px;
}

.play-img {
    position: absolute;
    left: 38%;
    right: auto;
    top: 28%;
    bottom: auto;
    opacity: 0.9;
}

.demand-img {
    position: relative;
}

.demand-back {
    position: relative;
    top: -8px;
    left: -15px;
}

.section_header {
    background: $TurquoiseBlue;
    padding: 8px 22px;
    color: $White;
    margin-bottom: 0;
}

.img-rounded {
    border-radius: 5px;
}

.time-box {
    width: 80px;
    border-radius: 7px;
    color: white;
    text-align: center;
    font-family: Msemibold;
    font-size: 1em;
    position: absolute;
    right: 0px;
    bottom: 0px;
    //background: rgba(0,0,0,0.5);
    padding: 0.1em;

    .fa {
        font-size: 1.5em;
    }
}

.vid_time_new {
    right: 4px;
    bottom: 4px;
    background: rgba(0, 0, 0, 0.5);
    padding: 0px 5px;
    border-radius: 3px;
    height: 16px;
}

.time {
    font-size: 0.5rem !important;
    color: $White;
    position: relative;
    top: -6px;
}

.codemand {
    font-size: 0.9rem;
    word-break: break-word;
    line-height: 1.5rem;
}

.pad_list_btn {
    padding: 0.5rem 1rem !important;
    float: left;
    margin-bottom: 8px;
    width: 100%;
}

.list_bg {
    background: $BeamOchre20;
}

.section_sub_header {
    background: $GoldenTainoi;
    padding: 8px 22px;
    color: $White;
    margin-bottom: 0;
}

.blue_banner {
    background: $Lochmara;
}

.blog-photo {
    height: 48px;
    overflow: hidden;
}

.blog-content .blog-bottom img {
    width: 40px;
    height: 40px;
}

.dblog_head {
    font-size: 0.5rem !important;
    width: 80%;
    text-align: left;
    margin-top: 7px;
    margin-bottom: 5px;
}

.dblog_text {
    color: $Aluminium;
}

.dblog_btn {
    font-size: 0.5rem !important;
    padding: 6px 12px !important;
    width: 80%;
    text-align: center;
}

.ondemand_groups .group-btn {
    width: 100%;
    padding: 0.6rem 1.1rem !important;
}

.ondemand_groups .img-fluid {
    height: 50px;
}

.img-fluid-highlight {
    background-color: #4da198;
}

.ondemand_groups .last-lock {
    position: absolute;
    right: 9px;
    top: 8px;
}

.instruct--about2 .last-lock {
    position: absolute;
    right: 9px;
    top: 7px;
}

.ondemand_groups .search-btn .input-group-append .btn {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    background: $Lochmara;
    width: 140px;
    border-color: $Lochmara;
    height: 33px;
    font-size: 0.9rem;
    line-height: 16px;
}

.button-lightblue {
    background: $TurquoiseBlue;
    color: $White;
    padding: 0.442rem 1.5rem;
    font-size: 0.9rem;
    border-radius: 2rem;
    border: 0;
}

.instruct-card-design {
    border: 1px solid $Gallery;
    padding: 10px;
    border-radius: 5px;
    height: 100%;
}

.instruct_details {
    //max-height: 180px;
    position: relative;

    .read-btn {
        position: absolute;
        bottom: 5px;
    }

    .condition_status {
        line-height: 10px;
        padding-bottom: 3px;
    }
}

.bg-yellow {
    background: url("/assets/images/yellowblock.png");
    height: 250px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding: 20px;
    border-radius: 0.6rem;
}

.bg-orange1 {
    background: url("/assets/images/orangeblock.png");
    height: 250px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding: 20px;
    border-radius: 0.6rem;
}

.bg-purple {
    background: url("/assets/images/purpleblock.png");
    height: 250px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding: 20px;
    border-radius: 0.6rem;
}

.start-popup .modal-header {
    background: $OutrageousOrange !important;
}

.start-popup .modal-title1 {
    font-family: "Msemibold" !important;
}

.b4_start .modal-title1 {
    font-family: "Msemibold" !important;
}

.b4_start .modal-header {
    background: $GoldenTainoi !important;
}

.b4_start {
    width: 700px !important;
    max-width: 700px !important;
}

.result_list li {
    list-style-type: none;
    display: inline-block;
    padding: 16px 0;
    text-align: center;
}

.result_list li span {
    padding: 5px 19.9px;
    border-right: 1px solid $Mercury;
    font-family: "Msemibold" !important;
    font-size: 15px;
}

.result_list li:last-child span {
    border-right: none;
}

.result_list li:hover {
    background: $WhiteIce;
}

.result_list ul {
    border: 1px solid $Mercury;
    border-radius: 4px;
}

.b4_start .modal-body {
    min-height: 400px;
    background: url(/assets/images/popupbottom.png);
    background-position: 100% 100%;
    background-size: 100%;
    background-repeat: no-repeat;
}

.ques-txt {
    color: $Aluminium !important;
}

.why-text {
    position: absolute;
    right: 10px;
    bottom: 6px;
}

.after_start-bg .modal-body {
    min-height: 400px;
    background: url(/assets/images/popupbottom.png);
    background-position: 100% 100%;
    background-size: 100%;
    background-repeat: no-repeat;
}

.after_start .modal-title1 {
    font-family: "Msemibold" !important;
    color: #000 !important;
}

.after_start .modal-header {
    background: $IceCold !important;
}

.after_start {
    width: 700px !important;
    max-width: 700px !important;
}

.start-popup.common_design .popup-btn {
    margin-top: 60px !important;
    margin-bottom: 30px !important;
}

.welcome-popup .modal-header {
    background: $LightWisteria !important;
}

.welcome-popup .modal-title1 {
    font-family: "Msemibold" !important;
}

.welcome-popup.common_design .popup-btn {
    margin-top: 60px !important;
    margin-bottom: 30px !important;
}

.after_start .radio input[type="radio"]+.radio-label:before {
    width: 1.5rem;
    height: 1.5rem;
}

.rating {
    float: none;
    text-align: center;
    margin: 0 auto;
    display: table;
}

/* :not(:checked) is a filter, so that browsers that don’t support :checked don’t
    follow these rules. Every browser that supports :checked also supports :not(), so
    it doesn’t make the test unnecessarily selective */
.rating:not(:checked)>input {
    position: absolute;
    top: -9999px;
    clip: rect(0, 0, 0, 0);
}

.rating:not(:checked)>label {
    float: right;
    width: 1em;
    /* padding:0 .1em; */
    overflow: hidden;
    white-space: nowrap;
    cursor: pointer;
    font-size: 300%;
    /* line-height:1.2; */
    color: #ddd;
}

.text-message {
    border: 1px solid #ced4da;
}

.result_list {
    width: 76% !important;
}

.rating:not(:checked)>label:before {
    content: "★ ";
    font-size: 47px;
}

.rating>input:checked~label {
    color: $GoldenTainoi;
}

.rating:not(:checked)>label:hover,
.rating:not(:checked)>label:hover~label {
    color: $GoldenTainoi;
}

.rating>input:checked+label:hover,
.rating>input:checked+label:hover~label,
.rating>input:checked~label:hover,
.rating>input:checked~label:hover~label,
.rating>label:hover~input:checked~label {
    color: $GoldenTainoi;
}

.rating>label:active {
    position: relative;
    top: 2px;
    left: 2px;
}

.common_design {
    .modal-content {
        width: 100%;
        border-radius: 6px;
    }

    .centered-content {
        margin: 0 auto;
        display: table;
    }

    .modal-header {
        //height: 96px;
        width: 100%;
        border-radius: 6px 6px 0 0;
        background-color: $TurquoiseBlue;
    }

    .header-styling button.close {
        color: $white;
        opacity: 1;
        font-weight: normal;
        padding: 0;
    }

    .modal-title1 {
        @extend %Mregular;
        font-size: 1.5rem;
        margin-top: 20px;
    }

    .popup-txt {
        height: 39px;
        border-radius: 6px 8px 8px 8px;
        background-color: $White;
        box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.18);
        color: $Shark;
        font-size: 12px;
        @extend %Msemibold;
    }
}

.font-12 {
    font-size: 0.75rem !important;
}

.forgot-pass .popup-btn {
    width: 100%;
}

.forgot-pass .form-control {
    border: 1px solid #dddddd;
    background: #fefefe;
    border-radius: 4px;
}

.instruct_body {
    height: 133px;
    overflow: hidden;
}

.summary_div {
    overflow: initial;
    position: relative;
    float: left;
}

#instructor_details ul {
    padding-left: 20px;
}

.condition_status {
    line-height: 20px;
}

.brown-txt {
    color: $Fedora;
}

.ice-bg {
    background: $WhiteIce;
}

.filter-select div {
    display: inline-block;
    padding-right: 10px;
}

.lightpurple-bg {
    background: $Magnolia;
}

.about2 img {
    width: 419px;
}

.tab-btn {
    width: 262px;
    background: #ffffff !important;
    border-radius: 4px;
    padding: 5px;
    margin: 15px auto 0 !important;
    box-shadow: 0 0 0px 1px #f3f3f3;
    height: 39px;
    margin: 0 auto;

    .tab-select {
        background: #ffffff !important;
        color: $Amethyst;
        width: 100%;
    }

    .dropdown-menu.show {
        width: 100%;

        a {
            color: $Amethyst;
        }
    }
}

.filter-sm-section .filter-btn {
    height: 28px !important;
    margin-top: 10px;
}

.search-sm-btn input {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    font-size: 12px !important;
    height: 34px;
}

.search-sm-btn .input-group-append .btn {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    height: 34px;
    background: #72d6e6;
    color: #fff;
    padding: 6px 14px;

    i.fa.fa-search {
        font-size: 17px;
        color: $white;
    }
}

.common_design select {
    text-indent: 1px;
    text-overflow: "";
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding: 3px 3px 3px 11px;
    border: 1px solid $Alto;
    background: transparent url(/assets/images/selectblue.png) no-repeat;
    height: 48px;
    width: 100%;
    background-position: 96%;
    margin: 0 auto;
    display: table;
    color: $Shark;
    background-size: 15px;
}

.common_design {
    .DayPicker-NavBar {
        background: $TurquoiseBlue;

        height: 42px;
    }

    .DayPicker {
        margin: 17px auto;
        width: 100%;
    }

    .DayPicker-Months {
        width: 100%;
        box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.18);
    }

    .DayPicker-Month {
        margin: 0 !important;
        width: 100%;
    }

    .popup-btn {
        display: table;
        margin-top: 0 !important;
        line-height: 22px;
    }

    .DayPicker-Weekday {
        color: $Shark;
        font-size: 12px;
        @extend %Msemibold;
        width: 42px !important;
    }

    .DayPicker .fa {
        font-size: 25px;
        margin: 3px;
    }

    .DayPicker-WeekdaysRow {
        border-bottom: 1px solid #e0e0e0;
    }

    .DayPicker-Day--today {
        color: #d0021b !important;
        font-weight: 700;
    }

    .DayPicker-Day {
        font-size: 12px;
        @extend %Msemibold;
        color: $Shark;
        color: #2a2b2c;
        height: 36px;
        width: 30px;
        line-height: 14px;
        border-radius: 15.5px;
    }

    .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
        color: $White !important;
        background-color: $TurquoiseBlue !important;
    }

    .DayPicker-Caption {
        position: absolute;
        top: 6px;
        color: #fff;
        right: 45%;
        font-size: 16px;
        @extend %Msemibold;
    }
}

.pop-sub-head {
    font-size: 1.5rem;
}

.common_design .rounded-circle {
    width: 48px;
}

.on-demand-filter-sm-value {
    display: none;
}

.demand-section-mobile {
    display: none;
}

.mobile-slider .demand-profimg {
    width: 25px;
    height: 25px;
}

.slick-dots li button:before {
    font-size: 8px !important;
}

.mobile-slider {
    display: none;
}

.slick-arrow {
    display: none !important;
}

.Recent_blog .card-body {
    min-height: 120px;
}

.fs-14 {
    font-size: 1.1rem !important;
}

.padding-taketest {
    padding: 14px 60px !important;
}

@media #{$information-xtralarge} {
    .instruct_body {
        height: 124px;
        overflow: hidden;
    }

    .ondemand_groups .dblog_btn {
        font-size: 0.9rem !important;
        padding: 6px 12px !important;
        width: 80%;
        text-align: center;
    }
}

.mobile_value {
    display: none;
}

@media #{$information-large} {
    .common_design {
        width: 420px;
        max-width: 550px;

        .modal-header {
            //height: 78px;
        }
    }

    .ice-bg {
        background: #e3f7fa;
        height: auto;
    }

    .condemand .four_grid .left-content {
        width: 100px;
    }

    .demand-list .w-75 {
        //width: 90% !important;
    }

    .ondemand_groups .dblog_btn {
        font-size: 0.9rem !important;
        padding: 5px 14px !important;
        width: 80%;
        text-align: center;
    }

    .ondemand_groups .container-fluid {
        width: 85% !important;
    }

    .condemand .tab_group .tab_full {
        z-index: 1;
    }

    .condemand .tab_group .tab_full .tab_ul .nav-item {
        border-right: 1px solid #e9e9e9;
        padding: 0px 25px;
    }
}

@media #{$information-desktop} {
    .ondemand_groups .dblog_btn {
        font-size: 0.8rem !important;
    }

    .condemand .tab_group .tab_full {
        z-index: 1;
    }

    .condemand {
        .tab_group {
            .tab_ul {
                .nav-item {
                    padding: 0px 25px !important;
                    border-right: none !important;
                }
            }
        }
    }

}

@media #{$information-tablet} {
    .demand-list .w-75 {
        width: 75% !important;
    }

    .btn-quicklink {
        color: white;
        font-size: 12px;
        height: 34px;
        padding-left: 10px;
        padding-right: 10px;
        border-radius: 10px;
    }

    .bookmark-left span span {
        //display: none;
    }

    .demand-section-1 .video-details-left {
        padding-right: 0 !important;
    }

    .play-img {
        width: 70px;
    }

    .common_design .inputs-control {
        margin-bottom: 10px;
        padding: 0 15px !important;
    }

    .tab_full {
        .four_grid {
            .left-content {
                width: auto;
            }

            .extra-small-txt {
                font-size: 0.5rem;
            }
        }
    }

    .btn-purple-inverse {
        padding: 0.342rem 2.1rem !important;
    }

    .instruct_details {

        //height: auto;
        .read-btn {
            position: relative !important;
        }
    }

    .demand-section--desk .font-14 {
        font-size: 0.7rem !important;
    }

    .fitness-popup .read-later {
        height: 35px;
        padding: 0px 25px;
    }

    .fitness-save-btn {
        margin-left: 15px !important;
    }
}

@media #{$information-phone} {
    .b4_start .mobile_value {
        display: block;
        width: 80% !important;
        margin: 0 auto;
    }

    .after_start .mobile_value {
        display: block;
        width: 80% !important;
        margin: 0 auto;
    }

    .range_value_display {
        font-size: 55px;
        margin: 0 auto;
        width: 100%;
        text-align: center;
    }

    .range_value_denote {
        text-align: center;
        width: 100%;
        margin-top: 10px;
        margin-bottom: 10px;
        font-size: 20px;
    }

    .desk_value {
        display: none;
    }

    .on-demand-filter-sm-value .card-header img {
        width: 55px;
    }

    .on-demand-filter-sm-value .card-text:last-child {
        line-height: normal;
    }

    .demand-section--desk {
        display: none;
    }

    .mobile-slider {
        display: block;
    }

    .demand-img {
        position: relative;
        width: 100%;
        margin: 0;
    }

    .video-detail-bg {
        background: #f2f2f2;
        padding: 10px;
    }

    .demand-section-mobile {
        display: block;
    }

    .demand-section-1 .video-detail-desktop {
        display: none;
    }

    .b4_start {
        width: 100% !important;
    }

    .after_start {
        width: 100% !important;
    }

    .text-message {
        border: 1px solid #ced4da;
        width: 100%;
    }

    .result_list {
        width: 100% !important;
    }

    .result_list li span {
        border-right: none;
        font-family: "Msemibold" !important;
        font-size: 12px;
        padding: inherit !important;
    }

    .result_list li {
        list-style-type: none;
        display: inline-block;
        padding: 16px 0;
        text-align: center;
        width: 10%;
    }

    .desktop-ondemand-view {
        display: none;
    }

    .on-demand-filter-sm-value {
        display: block;
    }

    .demand-section-1 .bookmark-left .fa {
        font-size: 1rem;
    }

    .video-details-right {
        padding-right: 0 !important;
        padding-left: 5px !important;
    }

    .video-details-right .bookmark-left {
        margin-left: 7px !important;
    }

    .on-demand-filter-sm-value .card-header a[aria-expanded="true"] i:before {
        content: "\f106";
    }

    .on-demand-filter-sm-value .card-header a {
        font-size: 18px !important;
        color: #666366 !important;
        border-bottom: 1px solid rgba(102, 99, 102, 0.7) !important;
        padding: 10px 0;
        width: 100%;
        float: left;
        text-decoration: none;
        height: 44px;
        margin-bottom: 10px;
    }

    .on-demand-filter-sm-value .card-header {
        background: #fff;
        border: none;
        border-radius: 0;
        padding: 0;
    }

    .on-demand-filter-sm-value .card {
        border: none;
        background: #fff;
    }

    .bookmark-left span span {
        //display: none;
    }

    .demand-section-1 .video-details-left {
        padding-right: 0 !important;
    }

    .play-img {
        width: 40px;
    }

    .filter-sm-section .input-group-prepend .btn,
    .input-group-append .btn {
        z-index: 0;
    }

    .condemand .four_grid {
        margin-top: 0px !important;
    }

    .condemand .close-btn {
        margin-top: 10px;
        width: 100%;
    }

    .condemand .banner_demand h3 {
        padding: 0 !important;
    }

    .filter-sm-section {
        position: relative;
        // top: -13px;
    }

    .bookmark-left b {
        margin-left: 5px !important;
    }

    .card-body .left-image {
        margin-top: 0 !important;
    }

    .four_grid {
        .left-content {
            width: 100px !important;
        }

        .extra-small-txt {
            font-size: 1rem !important;
        }
    }

    .search-btn {
        .input-group-append {
            .btn {
                width: auto !important;
            }
        }
    }

    .filter-btn {
        margin: 0 auto;
        margin-top: 18px;
        display: table;
        width: 50%;
    }

    .group-btn {
        margin: 0 auto;
        margin-top: 18px;
        display: table;
        width: 50%;
    }

    .sort-btn {
        margin: 0 auto;
        display: table;
        margin-bottom: 10px;
    }

    .media figure {
        margin: 0 auto;
        display: table;
        float: none;
        margin-bottom: 10px;
    }

    .instructor-photo {
        text-align: center;
        margin-bottom: 10px;
    }

    .instructor-content {
        text-align: center;
        margin-bottom: 10px;
    }

    .instruct_details {
        height: auto;
        text-align: left !important;

        .read-btn {
            position: relative;
            text-align: center;
            margin: 0 auto;
        }

        ul {
            font-size: 0.8em;
            list-style-type: circle;
        }
    }

    .schedule_block {
        width: 100%;
        max-width: 100%;
    }

    .btn-quicklink {
        color: white;
        font-size: 12px;
        height: 34px;
        padding-left: 10px;
        padding-right: 10px;
        border-radius: 10px;
    }

    .value-rat {
        height: 32px;
        width: 49px;
        line-height: 30px;
    }

    .modal-beam-how .modal-dialog-post-poll {
        min-width: auto !important;
    }

    .value-txt.font-16 {
        font-size: 0.9rem !important;
    }

    .modal-dialog-post-poll .radio-btn {
        padding: 0;
    }

    .fitness-popup .form-group.field.field-integer {
        margin-right: 0px !important;
    }

    .test-button-padding {
        right: 0px !important;
    }

    .padding-taketest {
        padding: 14px 20px !important;
    }

    .fitnesspopup-buttons {
        width: 50% !important;
    }

    .fitness-popup .form-control {
        width: 50px;
        border-radius: 30px;
        margin-left: 0px;
        z-index: 1;
        margin-right: 0px;
    }

    .fitness-popup .read-later {
        padding: 0px 1.6rem;
    }

    .fitness-save-btn {
        margin-left: 10px !important;
    }

    .fitness-popup .bold-text {
        font-size: 12px !important;
    }

}

.zindex {
    z-index: 10;
}

.ondemand-preview {
    width: 50%;
}

.cms-model {
    .header-styling {
        background: none;
        padding: 10px;
        height: 70px;
    }

    .modal-title {
        color: gray;
    }
}

.optionWidth {
    width: 95%;
}

.removeTagBtn {
    width: 5%;
}

.modal-beam .modal-content {
    border-radius: 20px;
    margin-top: 80px;
}

.modal-beam .close {
    font-weight: 100;
    font-size: 40px;
    position: relative;
    top: -15px;
}

.modal-beam .modal-title {
    color: #53565a;
    text-align: center;
    font-size: 24px;
}

.modal-beam .heading-section-beam {
    padding-top: 10px;
    padding-right: 10px;
    height: 32px;
}

.modal-beam .sub-title {
    color: #0073c2;
    font-size: 18px;
    text-align: center;
    margin-top: 34px;
}

.option-head {
    font-size: 16px;
    color: #0073c2;
}

.lable-rad {
    color: #53565a;
}

.mradio {
    margin: 0.5rem;
}

.mradio input[type="radio"] {
    position: absolute;
    opacity: 0;
}

.mradio input[type="radio"]+.radio-label:before {
    content: "";
    background: #ffffff;
    border-radius: 100%;
    border: 1px solid #ff6633;
    display: inline-block;
    width: 1.4em;
    height: 1.4em;
    position: relative;
    top: -0.2em;
    margin-right: 1em;
    vertical-align: top;
    cursor: pointer;
    text-align: center;
    -webkit-transition: all 250ms ease;
    transition: all 250ms ease;
}
.mradio input[type="radio"]+.radio-label.yogacf:before {
    top: 0;
}
.mradio input[type="radio"]:checked+.radio-label:before {
    background-color: #ff6633;
    box-shadow: inset 0 0 0 4px #f4f4f4;
}

.mradio input[type="radio"]:focus+.radio-label:before {
    outline: none;
    border-color: #ff6633;
}

.mradio input[type="radio"]:disabled+.radio-label:before {
    box-shadow: inset 0 0 0 4px #f4f4f4;
    border-color: #b4b4b4;
    background: #b4b4b4;
}

.mradio input[type="radio"]+.radio-label:empty:before {
    margin-right: 0;
}

.lradio {
    margin: 0.5rem;
}

.lradio input[type="radio"] {
    position: absolute;
    opacity: 0;
}

.lradio input[type="radio"]+.radio-label:before {
    content: "";
    background: #ffffff;
    border-radius: 100%;
    border: 1px solid #ff6633;
    display: inline-block;
    width: 1.4em;
    height: 1.4em;
    position: relative;
    top: -0.2em;
    margin-right: 1em;
    vertical-align: top;
    cursor: pointer;
    text-align: center;
    -webkit-transition: all 250ms ease;
    transition: all 250ms ease;
}

.lradio input[type="radio"]:checked+.radio-label:before {
    background-color: #ff6633;
    box-shadow: inset 0 0 0 4px #f4f4f4;
}

.lradio input[type="radio"]:focus+.radio-label:before {
    outline: none;
    border-color: #ff6633;
}

.lradio input[type="radio"]:disabled+.radio-label:before {
    box-shadow: inset 0 0 0 4px #f4f4f4;
    border-color: #b4b4b4;
    background: #b4b4b4;
}

.lradio input[type="radio"]+.radio-label:empty:before {
    margin-right: 0;
}

.modal-beam .small-text {
    color: #0073c2;
    font-size: 12px;
    margin: 0;
}

.modal-beam ul {
    padding-top: 0;
    padding-left: 17px;
}

.bluebtn {
    background: #72d6e6;
    color: #fff;
    border-color: #72d6e6;
    padding: 0.342rem 1.1rem;
    border-radius: 2rem !important;
    font-size: 1rem;
    text-decoration: none !important;
    font-family: Msemibold !important;
    border: none;
}

.btn-disabled {
    background: #d8d8d8;
    color: #fff;
    padding: 0.342rem 1.1rem;
    border-radius: 2rem !important;
    font-size: 1rem;
    text-decoration: none !important;
    font-family: Msemibold !important;
    border: none;
}

.text-area-head {
    font-size: 16px;
    color: #2a2b2c;
}

.custom-tetarea {
    border-color: #979797;
    border-radius: 8px;
    width: 100%;
    height: 118px !important;
}

.modal-beam-how .modal-content {
    border-radius: 20px;
    margin-top: 80px;
}

.modal-beam-how .close {
    font-weight: 100;
    font-size: 40px;
    position: relative;
    top: -15px;
}

.modal-beam-how .modal-title {
    color: #2a2b2c;
    text-align: center;
    font-size: 24px;
}

.modal-beam-how .sub-title {
    color: #2a2b2c;
    font-size: 16px;
    text-align: center;
    margin-top: 34px;
    font-weight: normal;
}

.modal-beam-how .heading-section-beam {
    padding-top: 10px;
    padding-right: 10px;
    height: 32px;
}

.value-rat {
    color: #2a2b2c;
    height: 32px;
    width: 49px;
    float: left;
    text-align: center;
    border-radius: 20px;
    line-height: 30px;
}

.radionew input[type="radio"] {
    position: absolute;
    opacity: 0;
}

.radionew input[type="radio"]+.radio-label:before {
    content: "";
    background: #fff;
    border-radius: 100%;
    border: 1px solid #fff;
    display: inline-block;
    width: 1.8em;
    height: 1.8em;
    position: relative;
    top: 1px;
    margin-right: 1em;
    vertical-align: top;
    cursor: pointer;
    text-align: center;
    -webkit-transition: all 250ms ease;
    transition: all 250ms ease;
    right: -10px;
}

.radionew input[type="radio"]:checked+.radio-label:before {
    background-color: #72d6e6;
    box-shadow: inset 0 0 0 5px #fff;
}

.radionew input[type="radio"]:focus+.radio-label:before {
    outline: none;
    border-color: #3197ee;
}

.radionew input[type="radio"]:disabled+.radio-label:before {
    box-shadow: inset 0 0 0 4px #f4f4f4;
    border-color: #fff;
    background: #fff;
}

.radionew input[type="radio"]+.radio-label:empty:before {
    margin-right: 0;
}

.c {
    background: #9ad6cb;
}

.cl {
    background: #c2e6e0;
}

.c0 {
    background: #8dd6e9;
}

.cl0 {
    background: #bbe6f2;
}

.c1 {
    background: #86d1ef;
}

.cl1 {
    background: #b6e3f5;
}

.c2 {
    background: #93c6ea;
}

.cl2 {
    background: #beddf2;
}

.c3 {
    background: #208acb;
}

.cl3 {
    background: #79b9e0;
}

.c4 {
    background: #0b6cb6;
}

.cl4 {
    background: #6da7d3;
}

.c5 {
    background: #f3a87c;
}

.cl5 {
    background: #f8cbb0;
}

.c6 {
    background: #f47f55;
}

.cl6 {
    background: #f8b299;
}

.c7 {
    background: #f26927;
}

.cl7 {
    background: #f7a57d;
}

.c8 {
    background: #ef8686;
}

.cl8 {
    background: #f5b6b6;
}

.c9 {
    background: #f14952;
}

.cl9 {
    background: #f69297;
}

.c10 {
    background: #cf3333;
}

.cl10 {
    background: #e28484;
}

.cl11 {
    background: #cdcce6;
}

.c11 {
    background: #acaad5;
}

.cl12 {
    background: #c7b2d6;
}

.c12 {
    background: #a27fba;
}

.cl13 {
    background: #b89ecb;
}

.c13 {
    background: #885ea8;
}

.rate-sec {
    border-radius: 20px;
    padding: 0;
}

.value-txt {
    color: #2a2b2c;
    vertical-align: middle;
}

.bluebtn {
    background: #72d6e6;
    color: #fff;
    border-color: #72d6e6;
    padding: 0.342rem 1.1rem;
    border-radius: 2rem !important;
    font-size: 1rem;
    text-decoration: none !important;
    font-family: Msemibold !important;
    border: none;
}

.modal-beam-how .modal-dialog-post-poll {
    // width: 500px;
    min-width: 500px;
    width: 100%;
}

.line-bar .progress {
    height: 0.5rem;
}

.available-soon {
    top: 44%;
}

.thumbnail-overlay {
    background-color: #5b565b;
    opacity: 0.7;
    border-radius: 5px;
}

.available-soon.listing-page {
    top: 44%;
    background: #393a34;
    left: 25%;
    padding: 0px 10px;
    border-radius: 10px;
}

.lengthy-text {
    width: 470px !important;
    padding-right: 41px;
}

.container-width {
    max-width: 800px;
    margin: 0 auto;
    width: 100%;
    height: 400px;
    border: 1px solid #eee;
    padding: 30px;
    text-align: center;
}

.questions_styles {
    font-weight: 600;
    font-size: 20px;
    text-align: left;
    margin: 20px 0 50px 0;
}

.progressbar {
    display: flex;
    width: 100%;
    margin-top: 40px;
}

.progressbar .progresses {
    width: 20%;
}

.progressbar .progresses .clr1 {
    border-radius: 15px 0 0 15px;
}

.progressbar .progresses .clr5 {
    border-radius: 0 15px 15px 0;
}

.progresses span {
    display: block;
    text-align: center;
    font-size: 12px;
    font-family: arial;
    margin-top: 2px;
    font-weight: 600;
}

.progressbar .progresses button {
    border: 1px solid #c3e7e1;
    height: 40px;
    width: 100%;
    background-color: #fff;
    cursor: pointer;
    margin-bottom: 5px;
}

.progressbar button.clr1:hover,
.progressbar button.color1 {
    background-color: #75aed5;
    color: #fff;
}

.progressbar button.clr2:hover,
.progressbar button.color2 {
    background-color: #83b8d7;
    color: #000;
}

.progressbar button.clr3:hover,
.progressbar button.color3 {
    background-color: #98c8da;
    color: #fff;
}

.progressbar button.clr4:hover,
.progressbar button.color4 {
    background-color: #add7de;
    color: #000;
}

.progressbar button.clr5:hover,
.progressbar button.color5 {
    background-color: #c1e5e0;
    color: #fff;
}

.btn_bg_clr1 {
    background-color: #75AED5 !important;
}

.btn_bg_clr2 {
    background-color: #83B8D7 !important;
}

.btn_bg_clr3 {
    background-color: #98C8DA !important;
}

.btn_bg_clr4 {
    background-color: #ADD7DE !important;
}

.btn_bg_clr5 {
    background-color: #C1E5E0 !important;
}

.submit-btn {
    margin-top: 50px !important;
}

/*spotlighting style*/
.font-54 {
    font-size: 3.375rem !important;
}

.howitworks-page .vid_time {
    font-family: "Msemibold" !important;
}

.fitness-popup .form-group.field.field-integer {
    display: flex;
    padding-right: 0px;
}

.spotlight_page .section-1 {
    // height: 35.625rem;
}

.border-white {
    border: 1px solid #fff;
}

.filter-section .search-btn .input-group-append .btn {
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    height: 52px;
}

.filter-section .search-btn .form-control {
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    height: 50px;
}

.condemand .four_cardvid .vid_time,
.banner-card .vid_time {
    bottom: 13px;
}

span.leftarrow img {
    width: 42px;
    height: 100px;
}

.redfont {
    color: #ff6633;
}

@media (min-width: 992px) {
    .col-lg-3.banner-card {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
        flex: 0 0 27%;
        max-width: 27%;
    }

    .four_cardvid .col-lg-3 {
        flex: 0 0 22%;
        max-width: 22%;
    }
}

@media (max-width: 992px) {
    .spotlight_page .section-1 {
        // height: 35.625rem;
    }
}

@media only screen and (min-width: 992px) and (max-width: 2560px) {
    .spotlight-swiper-web {
        .swiper-slide {
            width: 405px !important;
        }
    }
}

@media only screen and (min-width: 1921px) and (max-width: 2560px) {
    .swiper-container {
        margin: 0px;

        .swiper-slide {
            margin-right: 10px !important;
        }
    }
}

@media (max-width: 767px) {
    .single-workout-img-mobile li {
        width: 80%;
    }

    .sp-banner {
        height: 100% !important;
    }

    .spotlight_page .carousel .slide {
        background-color: transparent !important;
        min-width: 270px !important;
    }

    .slide .selected .btn-orange {
        margin-top: 50px;
    }

    .spotlight_page .slider,
    .spotlight_page .slider:before {
        background-color: transparent !important;
    }

    .slider .carousel .slide {
        background-color: transparent !important;
        padding: 10px;
    }

    .carousel .slider-wrapper.axis-horizontal .slider .slide {
        padding: 0px 7px !important;
    }

    .spotlight_page .section-1 {
        height: 21rem;
    }

    .spotlight_page .font-54 {
        font-size: 2rem !important;
    }

    .spotlight_page .banner-card {
        margin-top: 0px !important;
    }

    .spotlight_page h5 {
        margin-top: 5px !important;
    }

    .spotlight_page .justify-content-center.align-self-center.m-t-50 {
        margin-top: 50px !important;
        text-align: left !important;
    }

    .srch_btn {
        width: 95% !important;
    }

    .four_cardvid .col-lg-3 {
        padding-left: 15px !important;
    }

    .workout-status .oval img {
        width: 17px !important;
        height: 12px;
        display: inline-block;
        position: relative;
        // top: 4px !important;
        bottom: 0px !important;
    }

    .removebbg-popup .styles_overlay__CLSq- {
        display: block !important;
    }

    .react-autosuggest__suggestions-container--open {
        top: 34px !important;
        width: 96.2% !important;
        margin-left: 15px !important;
    }

    section.condemand.spotlight_page {
        margin-left: -15px;
        margin-right: -15px;
    }

    .condemand.spotlight_page .section-header .carousel-wrapper .carousel-slider ul li:last-child {
        align-self: center !important;
        justify-content: center !important;
    }

    .condemand.spotlight_page .section-header .carousel-wrapper .carousel-slider ul li:last-child .text-center.m-t-50 {
        margin-top: 0px !important;
    }

    /* .single-workout-img-mobile .slide .image-size-new {
        width: 200px !important;
        margin-left: 10px;
    }
    .single-slide-width{
        width:220px;
        overflow: hidden;
    } */
    .single-workout-img-mobile .slide .image-size-new {
        width: 200px !important;
        margin-left: 15px;
    }

    .condemand .four_grid {
        margin-top: 0 !important;
        padding: 0px;
    }
    .b4_start.video_ondemand_modal .modal-body {
        min-height: 100%;
        width: 100%;
        padding: 15px !important;
    
    }
        .b4_start.video_ondemand_modal .modal-dialog.modal-width--custom .modal-content {
        width: 100% !important;
    
        }
         .react-responsive-modal-modal{
            width:100% !important;
            margin:0px !important;
        }
}

.sp-banner {
    background-size: contain;
    width: 100%;
    object-fit: cover;
    height: 100%;
}

.top-0 {
    top: 0 !important;
}

.input-search-clear .search-btn input {
    padding-right: 20px;
}

.close-item-search {
    position: absolute;
    right: 0px;
    top: 12px;
    z-index: 99;
}

.close-item-search i {
    font-size: 20px;
    color: #0056b3 !important;
}

.rectangle {
    border-radius: 3px;
    background-color: #ffffff;
    display: inline-block;
    vertical-align: middle;
    padding: 2px 4px;
    height: 16px;
    margin-right: 5px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.oval {
    display: inline-block;
    background-color: #ffffff;
    padding: 0px 4px;
    border-radius: 46%;
    vertical-align: middle;
    position: relative;
    right: -4px;
}

.completed-times {
    color: #0073c2;
    font-family: "Msemibold";
    font-size: 10px;
    font-weight: 600;
    letter-spacing: 0;
    text-align: center;
    float: left;
    line-height: 11px;
}

.workout-status .oval img {
    width: 16px;
    height: 13px;
    display: inline-block;
    position: relative;
    top: -2px;
}

.workout-status {
    position: absolute;
    right: 0;
    padding-top: 4px;
}

.input-search-clear {
    .search-btn {
        .wrapper {
            z-index: 9;

            input {
                &::placeholder {
                    font-size: 14px;
                }
            }

            .line {
                margin-left: 0px;
                margin-right: 0px;
            }
        }

        &.dropdown-open {
            .wrapper {
                border-radius: 24px 0px 24px 24px;
            }
        }
    }
}

.search-sm-btn {
    .App-header {
        width: 85%;
    }

    .wrapper {
        z-index: 9 !important;
        border-width: 1px 0px 1px 1px !important;

        input {
            &::placeholder {
                font-size: 14px !important;
            }
        }

        ul {
            li {
                font-size: 14px;
            }
        }

        .line {
            margin-left: 0px;
            margin-right: 0px;
        }
    }

    &.dropdown-open {
        .wrapper {
            border-radius: 20px 0px 20px 20px;
            border-width: 1px !important;
        }
    }

    .clear-icon {
        margin: -2px 14px 0px 0px;
    }
}

@media (min-width: 1600px) {
    .searchbox-width {
        width: 100vw;
    }
}

.btn-add-class {
    line-height: 22px;
    padding: 13px 10px;
    border-radius: 30px;
    background-color: #ff6633;
    font-weight: 600;
    letter-spacing: 0.01px;
    color: #fff;
}

.btn-add-class i {
    font-weight: normal;
    font-size: 16px;
    margin-right: 8px;
}

.input-search-clear .input-group .App-header div {
    height: auto !important;
}
// .removebbg-popup .styles_overlay__CLSq- {
//     display: none;
// }
.btn-refresh-workouts {
    line-height: 22px;
    padding: 13px 10px;
    border-radius: 30px;
    background-color: $BeamPurple;
    font-weight: 600;
    letter-spacing: 0.01px;
    color: #fff !important;
}

.btn-refresh-workouts .btn-refresh-workouts:hover {
    background: $Amethyst;
    transition: all 0.3s ease;
    color: #fff;
}

@media only screen and (max-width: 1366px) {
    .input-search-clear .input-group.search-btn {
        width: 100% !important;
    }
}

@media (min-width: 1200px) {
    .container-xxl {
        max-width: 1442px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .input-search-clear .input-group .App-header div {
        width: 100% !important;
        /*width: 330px !important;*/
    }

    .input-search-clear .input-group.search-btn {
        margin-top: 0px;
        margin-bottom: 10px;
        justify-content: left !important;
    }

    .input-search-clear .input-group .App-header .sc-hLBbgP.fwneQB {
        width: 100%;
    }

    .browse-all {
        /*max-width: 45%;*/
        float: left;
    }

    .input-search-clear {
        // max-width: 55%;
    }

    .add-class-btn {
        max-width: 20%;
    }

    .banner-seemore-btn button.btn.btn-see {
        width: 100%;
    }

    .add-class-btn button {
        font-size: 12px !important;
    }

    .add-class-btn {
        padding-left: 0px;
    }

    .search-bar-tab .App-header {
        width: 80%;
    }

    .search-bar-tab {
        max-width: 50%;
        flex: 0 0 50%;
    }

    .search-bar-tab .input-group-append {
        width: 20%;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .input-search-clear .input-group .App-header div {
        width: 530px !important;
    }

    .input-search-clear .input-group.search-btn {
        margin-top: 10px;
        margin-bottom: 10px;
        justify-content: left !important;
    }

    .input-search-clear .input-group .App-header .sc-hLBbgP.fwneQB {
        width: 100%;
    }

    .banner-seemore-btn button.btn.btn-see {
        float: left;
    }

    .section-header .banner-card {
        margin-bottom: 10px !important;
    }

    .banner-top-section {
        max-width: 100%;
        flex: 0 0 100%;
    }

    .banner-top-section h5.banner-text {
        width: 100%;
    }

    .add-class-btn .btn-add-class,
    .add-class-btn .btn-refresh-workouts {
        display: none;
    }

    .search-section-order {
        /*display: flex;
        flex-flow: column;*/
    }

    .search-section-order .browse-all {
        /*order: 2;*/
        max-width: 35%;
        flex: 0 0 35%;
    }

    .input-search-clear.search-bar-width {
        max-width: 60%;
        flex: 0 0 60%;
    }

    .input-search-clear .input-group .App-header div {
        width: 100% !important;
    }

    .workout-status {
        right: 22px;
        top: 10px;
    }

    .condemand .four_cardvid .vid_time,
    .banner-card .vid_time {
        right: 26px;
    }

    .search-auto-complete {
        width: 425px !important;
    }

    .spotlight-swiper-web {
        .swiper-slide {
            width: 200px !important;
        }
    }

    .btn-see-webview {
        bottom: 20px !important;
    }

    .add-class-btn button {
        font-size: 16px;
    }

    .search-bar-tab .App-header {
        width: 80%;
    }

    .search-section-order .search-bar-tab {
        max-width: 55%;
        flex: 0 0 55%;
    }

    .search-bar-tab .input-group-append {
        width: 20%;
    }
}

.card-edit-icon {
    position: absolute;
    bottom: 5px;
    left: 70px;
}

@media only screen and (max-width: 767px) {
    .filter-sm-section .justify-content-center .App-header div {
        height: auto !important;
    }

    .filter-sm-section .input-group.search-sm-btn {
        margin-bottom: 15px;
        margin-left: 13px;
        justify-content: center;
        margin-top: -5px !important;
    }

    .browse-all {
        padding-left: 0;
    }

    .filter-sm-section {
        padding-left: 0;
    }

    .add-class-btn {
        max-width: 70%;
        margin: 0 auto;
    }

    .browse_grid .col-md-2 {
        max-width: 100% !important;
        flex: 0 0 100% !important;
    }

    span.browse-paratext {
        margin-bottom: 10px;
        display: inline-block;
    }

    .browse_grid .pr-5 {
        padding-right: 15px !important;
    }

    .browse_grid .pl-5 {
        padding-left: 0px !important;
    }

    .browse-all {
        margin-left: 13px !important;
        text-align: center;
        margin-bottom: 0px !important;
    }

    body .spotlight_page h5.font-54 {
        margin-top: 0px !important;
        font-size: 24px !important;
        font-weight: 600;
        letter-spacing: 0.01px;
        line-height: 26px;
        text-align: center;
        margin-left: 0 !important;
        margin-bottom: 0px !important;
    }

    .section-header .banner-card .card-img-top {
        height: 100% !important;
    }

    .spotlight_page .banner-card {
        margin-top: 0px !important;
        margin-bottom: 0px !important;
    }

    .section-header {
        padding-left: 0px !important;
        padding-top: 15px !important;
        padding-bottom: 30px !important;
    }

    .four_cardvid .font-24.card-block {
        font-size: 24px !important;
        //margin-left: -10px;
        margin-left: 0px;
    }

    section.condemand.m-l-2.mr-5 {
        margin-left: 15px !important;
        margin-right: 15px !important;
    }

    .header-custom .navbar.fixed-top {
        padding: 0.5rem 0rem;
    }

    .btn-see {
        margin-top: 0px !important;
        margin-left: 20px;
        height: 40px !important;
        width: 140px !important;
    }
}

@media (min-width: 100px) and (max-width: 320px) {
    .add-class-btn {
        max-width: 85%;
    }

    body .spotlight_page h5.font-54 {
        font-size: 20px !important;
        text-align: center;
        margin-left: 10px;
    }
}

.back-btn,
.browse-all {
    cursor: pointer;
}

.m-t-58 {
    margin-top: 58px;
}

.m-b-88 {
    margin-bottom: 88px;
}

.section-header .banner-card .card-img-top {
    width: 100%;
    height: 100%;
    border-radius: 0;
}

.p-b-16 {
    padding-bottom: 16px;
}

h5.banner-text {
    color: rgb(255, 255, 255);
    font-weight: 600;
    letter-spacing: 0.02px;
    line-height: 66px;
    margin-left: 72px;
    width: 753px;
}

.banner-seemore-btn button.btn {
    width: 214px;
    height: 50px;
}
span.back-arrow-black i {
    font-size: 16px;
}
span.back-arrow-black i {
    vertical-align: middle;
}
.m-l-2 {
    margin-left: 2.063rem !important;
}

.m-r-2 {
    margin-right: 3rem !important;
}

.m-t-33 {
    margin-top: 2.063rem;
}

.browse_grid .col-md-2 {
    max-width: 20%;
    flex: 0 0 20%;
}

.m-b-15 {
    margin-bottom: 15px;
}

span.browse-paratext {
    color: #2a2b2c;
    font-weight: normal !important;
}

/*modal*/
.removebbg-popup .styles_overlay__CLSq- {
    z-index: 2;
    padding: 0 !important;
}

.removebbg-popup .styles_modal__gNwvD.styles_modalCenter__L9F2w {
    margin-top: 80px !important;
    padding: 0 !important;
}

.removebbg-popup div#accordion {
    height: 100vh;
    background: #fff;
}

.removebbg-popup .card {
    border: none;
    border-radius: 0;
    width: 100%;
}

.removebbg-popup .card-header {
    background: #fff;
    box-shadow: none !important;
    border-top: 0.8px solid rgba(102, 99, 102, 0.3) !important;
    padding: 0px 0.938rem;
    border-left: 0;
    border-right: 0;
}

.removebbg-popup .card-header h5 {
    padding: 0.73rem 0px 0.73rem;
}

.removebbg-popup .collapse.show .card-body {
    padding: 0.43rem 1.25rem 0.463rem;
}

.input-group.search-sm-btn {
    //z-index: -1;
}

.removebbg-popup .card-header h5 a[data-toggle="collapse"] {
    color: #666366;
    letter-spacing: 0.01px;
    line-height: 20px;
    text-decoration: none;
    cursor: pointer;
}

.removebbg-popup .collapse.show .card-body span,
.removebbg-popup .collapse.show .card-body span p {
    color: #2a2b2c;
    letter-spacing: 0.01px;
    line-height: 30px;
    margin-bottom: 0 !important;
    padding-left: 0px;
    text-decoration: none;
}

.removebbg-popup .card-header h5 a[aria-expanded="true"] i:before {
    transform: rotate(-180deg);
    display: inline-block;
}

.removebbg-popup .w-100#accordion .card-header .fa-times:before {
    content: "";
    background-image: url(/assets/images/close.png);
    background-size: cover;
    width: 12px;
    height: 12px;
    position: absolute;
    right: 2px;
    top: 13px;
    filter: brightness(20%) contrast(90%);
}

.swiper-button-next:after,
.swiper-container-rtl .swiper-button-prev:after,
.swiper-button-prev:after,
.swiper-container-rtl .swiper-button-next:after {
    content: "" !important;
    width: 35px;
    height: 100px;
    position: absolute;
    top: -20px;
    background-size: contain;
    background-repeat: no-repeat;
    cursor: pointer;
}

.swiper-button-next:after,
.swiper-container-rtl .swiper-button-prev:after {
    background-image: url(/assets/images/next-slide.png);
    right: -13px;
    z-index: 1;
}

.swiper-button-prev:after,
.swiper-container-rtl .swiper-button-next:after {
    background-image: url(/assets/images/prev-slide.png);
    left: -13px;
}

.border-white-2 {
    border: 2px solid #fff;
}

.add-class-btn .btn-add-class:hover {
    background: #ff470a;
    transition: all 0.3s ease;
    color: #fff;
}

.condemand .filter_grid a:hover,
.condemand .filter_grid a:hover p,
.removebbg-popup .collapse.show .card-body span:hover,
.removebbg-popup .collapse.show .card-body span p:hover,
.removebbg-popup .collapse.show .card-body span a:hover {
    color: #ff6633;
    text-decoration: none;
}

/*search bar*/
.react-autosuggest__container {
    position: relative;
}

.react-autosuggest__input {
    width: 100%;
    height: 52px;
    padding: 0px 20px;
    font-family: Qregular;
    font-size: 0.875rem;
    border: 2px solid #dedfe0;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    border-color: #dbdbdb;
    letter-spacing: 0.01px;
    line-height: 22px;
}

.react-autosuggest__suggestions-container {
    display: none;
}

.react-autosuggest__suggestions-container--open {
    height: auto;
    max-height: 300px;
    overflow-y: auto;
    background: #fff;
    display: block;
    position: absolute;
    top: 51px;
    width: 96.5%;
    border: 1px solid #dbdbdb;
    padding: 0px;
    font-family: Qregular;
    font-size: 0.875rem;
    z-index: 2;
    border-color: #dbdbdb;
    margin-left: 20px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    letter-spacing: 0.01px;
    line-height: 22px;
}

.react-autosuggest__suggestions-container--open::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

.react-autosuggest__suggestions-container--open::-webkit-scrollbar-track {
    background: #ebebeb;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-border-radius: 10px;
    border-radius: 10px;
}

.react-autosuggest__suggestions-container--open::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

.react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.react-autosuggest__suggestion {
    cursor: pointer;
    padding: 8px 20px;
}

.react-autosuggest__suggestion--highlighted {
    background-color: #ddd;
}

@media (min-width: 100px) and (max-width: 320px) {
    .filter-sm-section .search-sm-btn .App-header {
        width: 80%;
    }
}

.wrapper::-webkit-scrollbar {
    /* corme */
    display: none;
}

.wrapper {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.br-30 {
    border-radius: 30px;
}

.fitness-popup .form-group.field.field-integer {
    display: flex;
    padding-right: 0px;
}

.fitness-popup {
    position: relative;
    margin-bottom: 0px;

    label {
        font-size: 1.12rem;
    }

    .form-control {
        width: 70px;
        border-radius: 30px;
        margin-left: 10px;
        z-index: 1;
        text-align: center;
    }

    .read-later {
        height: 35px;
    }
}

.fitnesspopup-buttons {

    .btn-yellow {
        width: 92px;
        padding: 8px 15px;
        font-size: 15px !important;
        margin-bottom: 0px;
    }

}

.video-react .video-react-big-play-button:before {
    content: "" !important;
    background-size: 70px 70px !important;
    display: inline-block;
    width: 300px !important;
    height: 300px !important;
    background: url("/assets/images/b-play-btn.png") no-repeat 0 0;

}

.video-react .video-react-big-play-button {
    border: none !important;
}

.web-workouts {
    width: 386px !important;
    margin-left: 20px;
    border-radius: 0px;
    border: 1px solid #fff !important;
    min-height: 234px !important;
}

.btn-see {
    margin-left: 20px;
    height: 50.05px;
    width: 214px;
}

.spotlight-swiper-web {
    .swiper-container {
        margin: 0px;

        .swiper-slide {
            // margin-right: 0px !important;
        }
    }
}

@media (min-width: 1024px) and (max-width: 1920px) {
    .swiper-slide .web-workouts {
        // width: 300px !important;
        // min-height: 182px !important;
        width: 100% !important;
        min-height: 100% !important;
    }

    .spotlight-swiper-web .swiper-slide {
        width: 325px !important;
    }

    .btn-see-webview {
        // right: -400px !important;
        right: -340px !important;
        bottom: 50px !important;
    }

    .spotlight-swiper-web{
        .swiper-container{
            // margin: 0px;
            .swiper-slide{
                // margin-right: 10px !important;
            }
        }
    }
}

// @media (max-width: 1024px) and (min-width: 769px) {
//     .swiper-slide .web-workouts {
//         width: 200px !important;
//         min-height: 122px !important;
//     }
// }
@media (min-width: 1330px) and (max-width: 1920px) {
    .spotlight-swiper-web .swiper-container .swiper-slide {
        // margin-right: 80px!important;
    }
}
@media (max-width: 1024px) and (min-width: 769px){
    .swiper-slide .web-workouts{
        width: 24.125rem !important;
        min-height:182px !important;
        height: 14.625rem;
    }

    .spotlight-swiper-web .swiper-slide {
        width: 225px !important;
    }

    .btn-see-webview {
        right: -435px !important;
        bottom: 45px !important;
        width: 200px;
        height: 45px;
    }

    .spotlight-swiper-web {
        .swiper-container {
            margin: 0px;

            .swiper-slide {
                margin-right: 135px !important;
            }
        }
    }

    .spotlight-swiper-web .swiper-container {
        margin-top: -25px;
    }
}
@media only screen and (min-width: 1275px) and (max-width: 1285px) {
    body .swiper-slide .card.web-workouts {
        width: 21.5rem!important;
        min-height: 181.5px!important;
        height: 11rem;
    }
    body .spotlight-swiper-web .swiper-slide {
        width: 300px!important;
    }
    body .swiper-slide .card.web-workouts .image-size-new {
        width: 300px;
        height: 180px;
    }
}
@media only screen and (min-width: 1275px) and (max-width: 1285px) {
    .search-bar-width .react-autosuggest__container input.react-autosuggest__input {
        width: 90%;
        padding: 0 12px;
    }
}

.spotlight-mobile-tilet {
    padding-top: 2em;
    padding-bottom: 2em;
}


.fitness-save-btn {
    margin-left: 15px;
}

.demand-section--3 {
    .swiper-slide {
        background: transparent;
    }
}
