.chat-app {
  width: 100%;
  background: #dce0f0;
  .chat-container {
    margin: auto;
    border-radius: 10px;
  }

  .header {
    align-items: center;
    padding: 10px;
    border-radius: 10px 10px 0 0;
    width: 50%;
    margin: auto;
  }

  .message-box-header {
    align-items: center;
    padding: 10px;
    border-radius: 10px 10px 0 0;
    width: 100%;
  }

  .profile-pic {
    border-radius: 28px;
    width: 40px;
    height: 40px;
  }

  .header-text {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    width: 100%;
  }

  .name {
    font-weight: 600;
    font-size: 16px;
    color: #000000;
  }

  .status {
    font-size: 14px;
    color: #53565a;
    font-weight: 500;
  }

  .message-header {
    padding: 10px;
    width: 100%;
  }
  .messages-list {
    height: 500px;
    overflow-y: scroll;
    padding: 10px;
    border-radius: 10px;
    width: 50%;
    margin: auto;
    
    .sticky-input{
      position: sticky;
      top: 0;
      z-index: 1;
      padding: 10px;
    }
  }
  // .messages-list:hover {
  //   overflow-y: scroll;
  // }
  .messages-list::-webkit-scrollbar {
    width: 5px;
    margin-left:5px;
    }
    .messages-list::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #dce0f0;
    border-radius: 10px;
    }
    .messages-list::-webkit-scrollbar-thumb {
    background: #dce0f0;
    border-radius: 10px;
    }
    .messages-list::-webkit-scrollbar-thumb {
    background: #dce0f0;
    }
  .no-margin {
    margin-bottom: 0px;
    font-size: 16px;
    font-weight: 500;
    color: #000000;
  }

  .message-container {
    display: flex;
    margin-bottom: 10px;
    flex-direction: row;

    &.you {
      flex-direction: row-reverse;
    }
  }

  .message-bubble {
    background: #ffffff;
    color: #000000;
    margin: 5px;
    padding: 10px 14px 10px 14px;
    border-radius: 0px 22px 22px 22px;
    &.you {
      padding: 10px 14px 10px 14px;
      border-radius: 22px 0px 22px 22px;
      background-color: #456eb0;

      & p {
        color: #ffffff;
        margin-top: 0.3rem;
        margin-bottom: 0.3rem;
      }
    }
  }
  .message-sender-name {
    margin-left: 30px;
    font-weight: 600;
    font-size: 14px;
    color: #000000;
  }
  .message-sender-time {
    font-weight: 500;
    font-size: 12px;
    color: #53565a;
    margin-right: 20px;
  }
  .send-message-form {
    display: flex;
    align-items: center;
    padding: 10px;
    border-radius: 0 0 10px 10px;
    width: 50%;
    margin: auto;
  }
  .input-container {
    display: flex;
    align-items: start;
    flex: 1 1;
    background-color: #ffffff;
    border-radius: 16px;
    height: 100px;
  }
  .textbox-input {
    flex: 1 1;
    padding: 10px 0px;
    border: none;
    border-radius: 10px;
    margin-right: 10px;
    font-size: 16px;
    font-weight: 500;
    color: #667085;
    height: 6.2em;
    overflow: hidden;
    margin-left: 10px;
  }
  .icon-button {
    background: transparent;
    border: none;
    cursor: pointer;
    font-size: 20px;
  }

  .button {
    padding: 10px 20px;
    background: #456eb0;
    color: #ffffff;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    margin-right: 15px;
  }
  .chatheader-borderbottom {
    border-bottom: 1px solid #b9c0e1;
  }
  .bottomText {
    width: 50%;
    font-size: 14px;
    font-weight: 600;
    color: #707073;
    margin: auto;
    text-align: justify;
  }
  .lock-image {
    max-width: 25px;
  }
}
.message-container.you .message-box-header {
  width: 90% !important;
}

@media (max-width: 767px) {
  .chat-app {
    .header-text {
      margin-left: 15px;
    }
    .send-message-form {
      width: 100%;
      margin: 0px;
    }
    .header {
      padding: 10px;
      width: 100%;
      margin: 10px 0px;
    }
    .name {
      font-size: 14px;
    }
    .status {
      font-size: 12px;
    }
    .messages-list {
      width: 100%;
      margin: 0px;
      overflow: auto;
    }
    .bottomText {
      width: 100%;
      text-align: left;
    }
  }
}

.emoji-picker-popup {
  position: absolute;
  bottom: -50dvh;
  right: 10px; 
  z-index: 1000;
}
